import React, { type ReactElement } from 'react'
import { type StudentData } from '../../../constants/types'
import { useParseDate } from '../../../hooks/useParseDate'
import { Link } from 'react-router-dom'
import DefaultAvatar from '../../../assets/default_avatar.svg'
import { useTranslation } from 'react-i18next'
import { TEACHER_HOMEWORK_SOLUTION_EDIT_PATH } from '../../../constants/teacherRoutes'

const TeacherHomeworkWithSolutionsListItem = ({ student }: { student: StudentData }): ReactElement => {
  const { toLocalDateWithHour } = useParseDate()
  const { t } = useTranslation('translations')

  const solutionStatus = (): ReactElement => {
    if (student?.homework_solution?.teacher_review) {
      return (
        <div className="lg:col-span-2 bg-green-200 py-1 px-2 rounded-lg w-max">
          <div className="text-green-900">
            {t('teachers.homeworks.statuses.checked')}
          </div>
        </div>
      )
    }
    if (student?.homework_solution) {
      return (
        <div className="lg:col-span-2 bg-gray-200 py-1 px-2 rounded-lg w-max">
          <div className="text-gray-600">
            {t('teachers.homeworks.statuses.to_check')}
          </div>
        </div>
      )
    }
    return (
      <div className="lg:col-span-2 bg-red-100 py-1 px-2 rounded-lg w-max">
        <div className="text-red-900">
          {t('teachers.homeworks.statuses.not_sent')}
        </div>
      </div>
    )
  }

  return (
    <Link
      to={TEACHER_HOMEWORK_SOLUTION_EDIT_PATH.replace(':id', student?.homework_solution?.id.toString())}
      className="card grid lg:grid-cols-12 py-3 px-5 lg:h-16 text-sm flex items-center"
    >
      <div className="col-span-3 hidden lg:block">
        <div className="flex gap-3 items-center">
          <img
            src={student?.user?.avatar?.url ?? DefaultAvatar}
            className="object-cover cursor-pointer h-7 w-7 rounded-full"
            alt="Avatar"
          />
          {student?.user?.name}
        </div>
      </div>
      <div className="col-span-2 hidden lg:block">
        {solutionStatus()}
      </div>
      <div className="lg:col-span-2 opacity-60 py-1 px-2 rounded-lg w-max hidden lg:block">
        {toLocalDateWithHour(student?.homework_solution?.created_at)}
      </div>
      <div className="col-span-5 truncate opacity-50 hidden lg:block">
        {student?.homework_solution?.teacher_review}
      </div>
      <div className="hidden lg:block">
      </div>
      <div className="block lg:hidden">
        <div className="card bg-white px-4 py-4 shadow-sm rounded-xl border">
          <Link to={TEACHER_HOMEWORK_SOLUTION_EDIT_PATH.replace(':id', student?.homework_solution?.id.toString())}>
            <div className="flex justify-between items-center mb-5">
              <div className="font-extrabold text-base">
                <div className="flex gap-3 items-center">
                  <img
                    src={student?.user?.avatar?.url ?? DefaultAvatar}
                    className="object-cover cursor-pointer h-7 w-7 rounded-full"
                    alt="Avatar"
                  />
                  {student?.user?.name}
                </div>
              </div>
              <div className="flex gap-2">
                {solutionStatus()}
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-7 h-7">
                  <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                </svg>
              </div>
            </div>
            <div className="flex gap-2 mb-4">
              <div>
                {t('teachers.homeworks.heading.date_sent_mobile')}
              </div>
              {student?.homework_solution?.created_at
                ? toLocalDateWithHour(student?.homework_solution?.created_at)
                : '-'}
            </div>
            <div className="grid grid-cols-[auto,1fr] gap-2">
              <div>
                {t('teachers.homeworks.heading.comment_mobile')}
              </div>
              <div className="truncate">
                {student?.homework_solution?.teacher_review
                  ? student?.homework_solution?.teacher_review
                  : '-'}
              </div>
            </div>
          </Link>
        </div>
      </div>
    </Link>
  )
}

export default TeacherHomeworkWithSolutionsListItem
