import React, { type ReactElement } from 'react'
import { type LessonScheduleImportData } from '../../../constants/types'
import { useParseDate } from '../../../hooks/useParseDate'
import { useTranslation } from 'react-i18next'
import Bar from '../../common/Bar'
import { Link } from 'react-router-dom'
import { ADMIN_EDIT_LESSON_SCHEDULE_IMPORT_PATH } from '../../../constants/adminRoutes'

const LessonScheduleImportListItem = ({ lessonScheduleImport }: { lessonScheduleImport: LessonScheduleImportData }): ReactElement => {
  const { toLocalDate } = useParseDate()
  const { t } = useTranslation('translations')

  const statusBar = (): ReactElement => {
    switch (lessonScheduleImport.status) {
    case 'created': return <Bar text={t('admin.lesson_schedule_imports.statuses.created')} bgColor="blue-400" textColor="white"/>
    case 'ready': return <Bar text={t('admin.lesson_schedule_imports.statuses.ready')} bgColor="blue-700" textColor="white"/>
    case 'successful': return <Bar text={t('admin.lesson_schedule_imports.statuses.successful')} bgColor="green-200" textColor="green-900"/>
    case 'pending': return <Bar text={t('admin.lesson_schedule_imports.statuses.pending')} bgColor="gray-200" textColor="gray-600"/>
    case 'failed': return <Bar text={t('admin.lesson_schedule_imports.statuses.failed')} bgColor="red-200" textColor="red-900"/>
    default: return <Bar text={t('admin.lesson_schedule_imports.statuses.failed')} bgColor="red-200" textColor="red-900"/>
    }
  }

  return (
    <div className="card grid grid-cols-12 py-3 px-5 lg:h-16 text-sm flex items-center">
      <div className="col-span-1">
        {lessonScheduleImport?.id}
      </div>
      <div className="col-span-1 opacity-50 truncate">
        {toLocalDate(lessonScheduleImport.created_at)}
      </div>
      <div className="col-span-3 flex justify-center">
        {statusBar()}
      </div>
      <div className="hs-tooltip col-span-5 [--placement:top] inline-block">
        {lessonScheduleImport.status !== 'successful' && (
          <div className="hs-tooltip-toggle opacity-50 truncate">
            {lessonScheduleImport.failure_reason}
            <span className="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded shadow-sm dark:bg-neutral-700" role="tooltip">
              {lessonScheduleImport.failure_reason}
            </span>
          </div>
        )}
      </div>

      <div className="col-span-2 flex justify-end">
        {lessonScheduleImport.status === 'ready' && (
          <Link
            to={ADMIN_EDIT_LESSON_SCHEDULE_IMPORT_PATH.replace(':id', lessonScheduleImport?.id?.toString())}
            className="px-4 py-1 w-max border border-1 border-[#3758F9] rounded-2xl text-[#3758F9]"
          >
            {t('admin.lesson_schedule_imports.show_button')}
          </Link>
        )}
      </div>
    </div>
  )
}

export default LessonScheduleImportListItem
