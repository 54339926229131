import React, { type ReactElement } from 'react'
import { type LessonScheduleData } from '../../../constants/types'
import { useParseDate } from '../../../hooks/useParseDate'

const LessonScheduleListItem = ({ lessonSchedule }: { lessonSchedule: LessonScheduleData }): ReactElement => {
  const { toLocalDate } = useParseDate()

  return (
    <div className="card grid grid-cols-4 lg:grid-cols-12 py-3 px-5 text-sm flex items-center">
      <div className="col-span-1 truncate hidden lg:block">{lessonSchedule?.id}</div>
      <div className="col-span-3 truncate hidden lg:block">{toLocalDate(lessonSchedule.starts_on)}</div>
      <div className="col-span-3 truncate hidden lg:block">{toLocalDate(lessonSchedule.ends_on)}</div>
      <div className="col-span-4 truncate hidden lg:block">{toLocalDate(lessonSchedule.created_at)}</div>
      <div className="col-span-1 block lg:hidden" />
    </div>
  )
}

export default LessonScheduleListItem
