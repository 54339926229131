import React, { type ReactElement, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useApiFetch } from '../../../hooks/api/useApiFetch'
import { useApiPost } from '../../../hooks/api/useApiPost'
import { useParams } from 'react-router-dom'
import { map, each, reduce, isEmpty } from 'underscore'
import {
  API_ADMIN_TEACHERS_PATH,
  API_ADMIN_EDIT_LESSON_SCHEDULE_IMPORTS_PATH,
  API_ADMIN_CLASS_UNITS_PATH,
  API_ADMIN_SUBJECTS_PATH,
  API_ADMIN_CLASSROOMS_PATH,
  API_ADMIN_STUDENTS_PATH,
  API_ADMIN_LESSON_SCHEDULE_IMPORTS_IMPORTS_PATH,
  API_ADMIN_SUBJECT_LEVELS_PATH
} from '../../../constants/apiRoutes'
import { ADMIN_LESSON_SCHEDULE_IMPORTS_PATH } from '../../../constants/adminRoutes'
import Select from '../../../components/common/Select'
import HSAccordion from '@preline/accordion'

const LessonScheduleLessonImportDetailsView = (): ReactElement => {
  interface DuplicateRecord {
    id: string | null
    name: string
  }

  interface ResolutionRecord {
    action: 'create' | 'use_existing'
    selectedId?: string
  }

  type ResolutionMap = Record<string, Record<string, ResolutionRecord>>
  type PossibleDuplicates = Record<string, DuplicateRecord[]>

  const { t } = useTranslation('translations')
  const queryParams = useParams()
  const apiUrl = API_ADMIN_EDIT_LESSON_SCHEDULE_IMPORTS_PATH.replace(':id', (queryParams.id as string))
  const { data: lessonScheduleImport } = useApiFetch({ url: apiUrl })

  const { data: teachers } = useApiFetch({ url: API_ADMIN_TEACHERS_PATH })
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { data: class_units } = useApiFetch({ url: API_ADMIN_CLASS_UNITS_PATH })
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { data: subject_levels } = useApiFetch({ url: API_ADMIN_SUBJECT_LEVELS_PATH })
  const { data: subjects } = useApiFetch({ url: API_ADMIN_SUBJECTS_PATH })
  const { data: classrooms } = useApiFetch({ url: API_ADMIN_CLASSROOMS_PATH })
  const { data: students } = useApiFetch({ url: API_ADMIN_STUDENTS_PATH })

  const { sendData, setInput, validationErrors } = useApiPost({
    url: API_ADMIN_LESSON_SCHEDULE_IMPORTS_IMPORTS_PATH.replace(':id', (queryParams.id as string)),
    redirect: ADMIN_LESSON_SCHEDULE_IMPORTS_PATH,
    successFlashMessage: 'Rozpoczęto import'
  })

  const [resolutions, setResolutions] = useState<ResolutionMap>({})
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  useEffect(() => {
    HSAccordion.autoInit()
  })

  useEffect(() => {
    if (lessonScheduleImport?.possible_duplicates) {
      const initialResolutions: ResolutionMap = {}

      each(lessonScheduleImport.possible_duplicates as PossibleDuplicates, (duplicates, entityType) => {
        each(duplicates, (item: DuplicateRecord, index) => {
          if (!initialResolutions[entityType]) {
            initialResolutions[entityType] = {}
          }

          initialResolutions[entityType][index] = {
            action: item.id ? 'use_existing' : 'create',
            ...(item.id ? { selectedId: item.id } : {})
          }
        })
      })

      setResolutions(initialResolutions)
    }
  }, [lessonScheduleImport])

  const handleResolutionChange = (
    entityType: string,
    importedId: string,
    action: 'create' | 'use_existing',
    selectedId?: string
  ): void => {
    setResolutions(prev => ({
      ...prev,
      [entityType]: {
        ...(prev[entityType] || {}),
        [importedId]: {
          action,
          ...(selectedId ? { selectedId } : {})
        }
      }
    }))
  }

  const mapRecordName = (entityType: string, record: any): string => {
    switch (entityType) {
    case 'class_units':
      return record.name
    case 'teachers':
      return record.user?.name
    case 'students':
      return record.user?.name
    case 'subjects':
      return record.name
    case 'classrooms':
      return record.number
    case 'subject_levels':
      return record.name
    default:
      return ''
    }
  }

  const handleSubmit = async (): Promise<void> => {
    setIsSubmitting(true)

    const formattedResolutions = reduce(resolutions, (acc, records, entityType) => {
      const entityResolutions = reduce(records, (entityAcc, resolution, index) => {
        if (!lessonScheduleImport?.possible_duplicates) return entityAcc

        const possibleDuplicates = lessonScheduleImport.possible_duplicates[entityType]
        if (!possibleDuplicates) return entityAcc

        const originalRecord = possibleDuplicates[parseInt(index)]

        if (resolution.action === 'use_existing' && resolution.selectedId) {
          entityAcc[originalRecord.name] = resolution.selectedId
        }

        return entityAcc
      }, {})

      if (!isEmpty(entityResolutions)) acc[entityType] = entityResolutions

      return acc
    }, {})

    const additionalParams = { duplicates: formattedResolutions }

    await sendData({ additionalParams })
    setIsSubmitting(false)
  }

  const renderDuplicateSection = (entityType: string, duplicates: DuplicateRecord[]): ReactElement | null => {
    if (!duplicates?.length) return null

    const dataMap = { class_units, teachers, subjects, classrooms, students, subject_levels }

    return (
      <div className="w-full hs-accordion-group shadow border border-gray-200 rounded-2xl bg-white mb-4">
        <div className='hs-accordion'>
          <button
            className="hs-accordion-toggle py-5 px-4 inline-flex items-center justify-between gap-x-3 w-full font-semibold text-start hover:text-gray-500 rounded-lg"
          >
            {t(`admin.lesson_schedule_imports.duplicates.${entityType.toLowerCase()}`)}
            <svg
              className="w-4 h-4 hs-accordion-active:hidden block"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="m6 9 6 6 6-6" />
            </svg>
            <svg
              className="w-4 h-4 hs-accordion-active:block hidden"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="m18 15-6-6-6 6" />
            </svg>
          </button>
          <div className={'hs-accordion-content w-full px-4 overflow-hidden transition-[height] duration-300 hidden'}>
            <div className="py-4 space-y-4">
              {map(duplicates, (item, index) => (
                <div key={`${entityType}-${index}`} className="bg-gray-50 p-4 rounded-lg">
                  <div className="flex flex-col space-y-4">
                    <div>
                      <span className="font-medium">{t('admin.lesson_schedule_imports.duplicates.imported_record')}: </span>
                      <span>{item.name}</span>
                    </div>

                    <div className="flex space-x-4">
                      <label className="flex items-center">
                        <input
                          type="radio"
                          name={`${entityType}-${index}`}
                          className="mr-2"
                          checked={resolutions[entityType]?.[index]?.action === 'create'}
                          onChange={() => { handleResolutionChange(entityType, index.toString(), 'create') }}
                        />
                        {t('admin.lesson_schedule_imports.duplicates.create_new')}
                      </label>

                      <label className="flex items-center">
                        <input
                          type="radio"
                          name={`${entityType}-${index}`}
                          className="mr-2"
                          checked={resolutions[entityType]?.[index]?.action === 'use_existing'}
                          onChange={() => { handleResolutionChange(entityType, index.toString(), 'use_existing') }}
                        />
                        {t('admin.lesson_schedule_imports.duplicates.use_existing')}
                      </label>
                    </div>

                    {resolutions[entityType]?.[index]?.action === 'use_existing' && (
                      <Select
                        id={`${entityType}-${index}`}
                        options={map(dataMap[entityType], (record: any) => ({
                          value: record.id,
                          text: mapRecordName(entityType, record)
                        }))}
                        value={resolutions[entityType]?.[index]?.selectedId}
                        placeholder={t(`admin.lesson_schedule_imports.duplicates.${entityType}_placeholder`)}
                        onChange={(value) => { handleResolutionChange(entityType, index.toString(), 'use_existing', value) }}
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="w-full bg-white rounded-lg shadow-sm">
      <div className="p-6 border-b border-gray-200">
        <h2 className="text-xl font-semibold">
          {t('admin.lesson_schedule_imports.duplicates.header')}
        </h2>
      </div>
      <div className="p-6">
        <div className="space-y-6">

          <label>
            {t('admin.lesson_schedule_imports.attributes.starts_on')}
          </label>
          <br />
          <input
            type="date"
            className="w-full shadow-sm rounded-lg"
            onChange={(e) => { setInput((prevInput: any) => ({ ...prevInput, starts_on: e.target.value })) }}
          />
          <div className="text-sm text-red-500">
            {map(validationErrors.starts_on, (error: string): string => `${t('admin.lesson_schedule_imports.attributes.starts_on')} ${error}`).join(', ')}
          </div>
          <br />

          <label>
            {t('admin.lesson_schedule_imports.attributes.ends_on')}
          </label>
          <br />
          <input
            type="date"
            className="w-full shadow-sm rounded-lg"
            onChange={(e) => { setInput((prevInput: any) => ({ ...prevInput, ends_on: e.target.value })) }}
          />
          <div className="text-sm text-red-500">
            {map(validationErrors.ends_on, (error: string): string => `${t('admin.lesson_schedule_imports.attributes.ends_on')} ${error}`).join(', ')}
          </div>
          <br />

          {lessonScheduleImport?.possible_duplicates &&
            map(lessonScheduleImport.possible_duplicates as PossibleDuplicates, (duplicates, entityType) =>
              renderDuplicateSection(entityType, duplicates)
            )}

          <div className="flex justify-end mt-6">
            <button
              onClick={handleSubmit}
              disabled={isSubmitting}
              className="bg-blue-500 hover:bg-blue-700 text-white py-3 px-6 rounded disabled:opacity-50"
            >
              {isSubmitting
                ? t('admin.lesson_schedule_imports.duplicates.processing')
                : t('admin.lesson_schedule_imports.duplicates.submit')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LessonScheduleLessonImportDetailsView
