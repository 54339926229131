import React, { type ReactElement, useContext } from 'react'
import { type LessonData } from '../../constants/types'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { LESSON_PATH } from '../../constants/rolePaths'
import { TEACHER_LESSON_HOMEWORKS_PATH } from '../../constants/teacherRoutes'
import { useRolePath } from '../../hooks/useRolePath'
import { CurrentUserContext } from '../../contexts/users/CurrentUserContext'
import DefaultAvatar from '../../assets/default_avatar.svg'
import { TEACHER_ROLE, STUDENT_ROLE } from '../../constants/roles'

const LessonListItem = ({ lesson }: { lesson: LessonData }): ReactElement => {
  const { t } = useTranslation('translations')
  const classUnitNames = lesson.class_units && lesson.class_units.length > 0
    ? lesson.class_units.map(unit => unit.name).join(', ')
    : 'brak klas'

  const { currentUser } = useContext(CurrentUserContext)
  const rolePath = useRolePath(LESSON_PATH, currentUser)
  const lessonViewPath = rolePath.replace(':id', lesson.id.toString())

  const linkConfigs = [
    {
      path: '',
      label: t('lessons.links.topics')
    },
    {
      path: '',
      label: currentUser?.role === STUDENT_ROLE
        ? t('lessons.links.my_attendances')
        : t('lessons.links.attendances')
    },
    {
      path: '',
      label: currentUser?.role === STUDENT_ROLE
        ? t('lessons.links.my_grades')
        : t('lessons.links.grades')
    },
    {
      path: TEACHER_LESSON_HOMEWORKS_PATH.replace(':id', lesson.id.toString()),
      label: t('lessons.links.homeworks')
    }
  ]

  const LinkComponent = (): ReactElement => (
    <>
      {linkConfigs.map(({ path, label }) => (
        <Link
          key={label}
          to={path}
          className="px-4 py-1 lg:mx-1 w-max border border-1 border-blue-600 rounded-md text-blue-600 hover:text-white hover:bg-blue-600"
        >
          {label}
        </Link>
      ))}
    </>
  )

  const MobileLinks = (): ReactElement => (
    <>
      <div className="grid grid-cols-2 gap-2">
        {linkConfigs.slice(0, 2).map(({ path, label }) => (
          <Link
            to={path}
            key={path}
            className="px-2 py-1 lg:mx-1 w-full border border-1 border-blue-600 rounded-md text-blue-600 text-center"
          >
            {label}
          </Link>
        ))}
      </div>
      <div className="grid grid-cols-2 gap-2">
        {linkConfigs.slice(2).map(({ path, label }) => (
          <Link
            key={path}
            to={path}
            className="px-2 py-1 lg:mx-1 w-full border border-1 border-blue-600 rounded-md text-blue-600 text-center"
          >
            {label}
          </Link>
        ))}
      </div>
    </>
  )

  const DesktopTeachersList = (): ReactElement => (
    <div className="flex gap-2 items-center font-['DM Sans'] truncate">
      <img
        src={lesson.teachers[0]?.user?.avatar?.url ?? DefaultAvatar}
        className="object-cover cursor-pointer h-6 w-6 rounded-full"
        alt="Avatar"
      />
      <span className="truncate">
        {lesson.teachers.map(teacher => teacher.user.name).join(', ')}
      </span>
    </div>
  )

  const MobileTeachersList = (): ReactElement => {
    const displayTeachers = lesson.teachers.slice(0, 3)
    const hasMoreTeachers = lesson.teachers.length > 3

    return (
      <div className="flex flex-col gap-1">
        {displayTeachers.map((teacher, index) => (
          <div key={teacher.id} className="flex items-center gap-2">
            <img
              src={teacher.user?.avatar?.url ?? DefaultAvatar}
              className="object-cover cursor-pointer h-6 w-6 rounded-full"
              alt="Avatar"
            />
            <span>{teacher.user.name}</span>
          </div>
        ))}
        {hasMoreTeachers && (
          <div className="text-gray-500 text-sm pl-8">...</div>
        )}
      </div>
    )
  }

  return (
    <Link
      to={lessonViewPath}
      className="card grid lg:grid-cols-12 py-3 px-5 lg:h-16 text-sm flex items-center"
    >
      <div className="col-span-2 hidden lg:block">
        {lesson.subject.name}
      </div>
      {currentUser?.role === TEACHER_ROLE && (
        <div className="col-span-4 hidden lg:block">
          {classUnitNames}
        </div>
      )}
      {currentUser?.role === STUDENT_ROLE && (
        <div className="col-span-4 hidden lg:block">
          <DesktopTeachersList />
        </div>
      )}
      <div className="col-span-6 inline-flex justify-end lg:inline-flex hidden">
        <LinkComponent />
      </div>
      <div className="block lg:hidden w-full">
        <div className="card bg-white px-4 py-4 shadow-sm rounded-xl border">
          <Link to={lessonViewPath}>
            <div className="flex justify-between items-center mb-4">
              <div className="font-extrabold text-base">
                {lesson.subject.name}
              </div>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-7 h-7">
                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
              </svg>
            </div>
            <div className="flex flex-col gap-1">
              <div className="font-['DM Sans']">
                {t('lessons.heading.teacher')}:
              </div>
              <MobileTeachersList />
            </div>
            <div className="pt-2">
              {t('lessons.heading.class_units')}: {classUnitNames}
            </div>
            <div className="lg:hidden block grid grid-cols-1 gap-2 pt-5">
              <MobileLinks />
            </div>
          </Link>
        </div>
      </div>
    </Link>
  )
}

export default LessonListItem
