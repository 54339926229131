import React, { type ReactElement, useContext } from 'react'
import type { HomeworkData, PopoverProps } from '../../../constants/types'
import { useTranslation } from 'react-i18next'
import { useParseDate } from '../../../hooks/useParseDate'
import { Link } from 'react-router-dom'
import { CurrentUserContext } from '../../../contexts/users/CurrentUserContext'
import { STUDENT_NEW_HOMEWORK_SOLUTION_PATH, STUDENT_HOMEWORK_SOLUTION_EDIT_PATH, STUDENT_HOMEWORK_SOLUTION_PATH } from '../../../constants/studentRoutes'
import { TEACHER_HOMEWORK_EDIT_PATH } from '../../../constants/teacherRoutes'
import { STUDENT_ROLE, TEACHER_ROLE } from '../../../constants/roles'

const HomeworkPopover = ({
  data: homework,
  position,
  onClose
}: PopoverProps<HomeworkData>): JSX.Element => {
  if (!position) {
    return (
      <>
        <div
          className="fixed inset-0 z-40"
          onClick={onClose}
        />
        <div className="fixed inset-x-4 bottom-0 -translate-y-1/2 z-50">
          <div
            className="bg-white shadow-lg w-full max-w-sm mx-auto rounded-xl"
            onClick={(e) => { e.stopPropagation() }}
          >
            <PopoverContent
              homework={homework}
              onClose={onClose}
              isMobile={true}
            />
          </div>
        </div>
      </>
    )
  }

  return (
    <div
      className="absolute bg-white rounded-xl shadow-lg w-80 border border-gray-200"
      style={{
        top: position.top,
        left: position.left,
        zIndex: 999,
        position: 'absolute'
      }}
    >
      <PopoverContent
        homework={homework}
        onClose={onClose}
        isMobile={false}
      />
    </div>
  )
}

const PopoverContent = ({
  homework,
  onClose,
  isMobile
}: {
  homework: HomeworkData
  onClose: () => void
  isMobile: boolean
}): JSX.Element => {
  const { t } = useTranslation('translations')
  const { toLocalHour } = useParseDate()
  const { currentUser } = useContext(CurrentUserContext)

  const homeworkSolutionPath = (): string => {
    if (solutionSubmitted() && homework.homework_solutions[0].teacher_review && homework.homework_solutions[0].teacher_review?.length > 0) {
      return (
        STUDENT_HOMEWORK_SOLUTION_PATH.replace(':homeworkId', homework.id.toString()).replace(':id', homework.homework_solutions[0].id.toString())
      )
    }

    if (solutionSubmitted() && canEditSolution()) {
      return (
        STUDENT_HOMEWORK_SOLUTION_EDIT_PATH.replace(':homeworkId', homework.id.toString()).replace(':id', homework.homework_solutions[0].id.toString())
      )
    }

    return STUDENT_NEW_HOMEWORK_SOLUTION_PATH.replace(':homeworkId', homework.id.toString())
  }

  const homeworkSolutionButtonLabel = (): ReactElement => {
    if (solutionSubmitted() && homework.homework_solutions[0].teacher_review && homework.homework_solutions[0].teacher_review?.length > 0) {
      return (
        <div className="flex gap-2">
          {t('homeworks.students.show_review')}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="h-5 w-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
            />
          </svg>
        </div>
      )
    }

    if (solutionSubmitted() && canEditSolution()) {
      return (
        <div className="flex gap-2">
          {t('homeworks.students.edit_solution')}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="h-5 w-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
            />
          </svg>
        </div>
      )
    }

    return (
      <div className="flex gap-2">
        {t('homeworks.students.add_solution')}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="h-5 w-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
          />
        </svg>
      </div>
    )
  }

  const homeworkEditPath = (): string => {
    return TEACHER_HOMEWORK_EDIT_PATH.replace(':id', homework.id.toString())
  }

  const fileUploadAfterDeadline = (): boolean => {
    return new Date(homework.file_upload_deadline) < new Date()
  }

  const solutionSubmitted = (): boolean => {
    return currentUser?.role === STUDENT_ROLE && homework.homework_solutions.length > 0
  }

  const canEditSolution = (): boolean => {
    return currentUser?.role === STUDENT_ROLE && (!fileUploadAfterDeadline() || solutionSubmitted())
  }

  return (
    <>
      <button
        onClick={onClose}
        className={`absolute ${isMobile ? 'top-3 right-3' : 'top-2 right-2'} text-gray-500 hover:text-gray-700`}
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
        </svg>
      </button>
      <div>
        <h3 className="font-semibold text-md border-l-blue-500 border-l-[3px] border-b rounded-t-xl pl-2 p-4">
          {homework.lesson?.name}: {homework.title}
        </h3>
        <div className="px-4 py-4 space-y-6">
          <div className="grid grid-cols-[80px_1fr] gap-x-3">
            <div className="text-xs text-gray-500 self-start">
              {t('calendar.homeworks.deadline')}
            </div>
            <div className="text-sm">
              {toLocalHour(new Date(homework.deadline))}
            </div>
          </div>

          {homework.description && (
            <div className="grid grid-cols-[80px_1fr] gap-x-3">
              <div className="text-xs text-gray-500 self-start">
                {t('calendar.homeworks.description')}
              </div>
              <div className="text-sm line-clamp-3">
                {homework.description}
              </div>
            </div>
          )}
        </div>
      </div>
      {currentUser?.role === STUDENT_ROLE &&
        (
          <Link
            to={homeworkSolutionPath()}
            className="flex items-center justify-end gap-2 pt-3 pb-5 px-5 text-sm text-blue-800"
          >
            {homeworkSolutionButtonLabel()}
          </Link>
        )
      }
      {currentUser?.role === TEACHER_ROLE &&
        (
          <Link
            to={homeworkEditPath()}
            className="flex items-center justify-end gap-2 pt-3 pb-5 px-5 text-sm text-blue-800"
          >
            {t('calendar.homeworks.edit_homework')}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="h-5 w-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
              />
            </svg>
          </Link>
        )
      }
    </>
  )
}

export default HomeworkPopover
