import React, { type ReactElement, useEffect } from 'react'
import { map } from 'underscore'
import { useApiPost } from '../../../hooks/api/useApiPost'
import { useTranslation } from 'react-i18next'
import { type StudentHomeworkSolutionData } from '../../../constants/types'
import DefaultAvatar from '../../../assets/default_avatar.svg'
import { TEACHER_HOMEWORK_PATH } from '../../../constants/teacherRoutes'

const TeacherHomeworkSolutionForm = ({ url, homeworkSolution, method = 'post' }: { url: string, homeworkSolution?: StudentHomeworkSolutionData, method?: string }): ReactElement => {
  const { setInput, sendData, validationErrors, input } = useApiPost({ url, redirect: TEACHER_HOMEWORK_PATH.replace(':id', homeworkSolution?.homework?.id?.toString() ?? ''), successFlashMessage: 'Pomyślnie zapisano' })
  const { t } = useTranslation('translations')

  useEffect(() => {
    if (homeworkSolution) {
      setInput({
        ...homeworkSolution
      })
    }
  }, [homeworkSolution])

  const saveHomeworkSolution = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault()
    await sendData({ method })
  }

  return (
    <form onSubmit={saveHomeworkSolution} className="min-h-screen flex flex-col justify-between">
      <div className="lg:px-12 px-5 py-8 mx-1">
        <div className='grid grid-cols-1 lg:grid-cols-2 lg:pb-20 lg:pb-0'>
          <div>
            <div className="flex gap-3 items-center pl-1">
              <img
                src={homeworkSolution?.student?.user?.avatar?.url ?? DefaultAvatar}
                className="object-cover cursor-pointer h-7 w-7 rounded-full"
              />
              {homeworkSolution?.student?.user?.name}
            </div>
            <h1 className="text-3xl text-left pb-14 pt-6 mx-1">{homeworkSolution?.homework?.title}</h1>
            <label className="text-lg font-extrabold ml-1">{t('teachers.homeworks.inputs.title')}</label>
            <br />
            <div className="ml-1 mt-4 mb-5 font-thin text-sm">
              {homeworkSolution?.homework?.description}
            </div>
            <div className="border bg-white border-gray-200 rounded-2xl pt-5 pb-7 mb-5 ml-1 shadow-sm">
              <label className="text-lg font-medium ml-1 px-3">{t('teachers.homework_solutions.inputs.student_answer')}</label>
              <div className="pt-5 px-4 font-thin text-sm h-60 max-h-60 overflow-y-auto">
                {homeworkSolution?.student_answer}
              </div>
            </div>
            <label className="text-lg font-medium ml-1">{t('teachers.homework_solutions.files')}</label>
            <div className="py-5 px-1 max-h-40 overflow-y-auto scrollbar-thin scrollbar-thumb-rounded">
              {homeworkSolution?.solution_files && homeworkSolution.solution_files.length > 0
                ? (
                  <div>
                    {homeworkSolution?.solution_files.map((file) => (
                      <div key={file.id} className="flex justify-between items-center">
                        <div className="text-sm">
                          {file.filename}
                        </div>
                        <a
                          href={file.url}
                          download
                          className="text-blue-800 hover:text-white flex gap-3 justify-center w-fit py-2 px-3 border border-blue-800 rounded-lg hover:bg-blue-800"
                        >
                          <div>
                            {t('teachers.homework_solutions.download_file')}
                          </div>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                          </svg>
                        </a>
                      </div>
                    ))}
                  </div>
                )
                : (
                  <p>{t('teachers.homework_solutions.no_files')}</p>
                )
              }
            </div>
          </div>
          <div className="lg:pl-36 lg:pt-0">
            <div className="text-2xl lg:mb-10 mb-5">
              {t('teachers.homework_solutions.comment')}
            </div>
            <div className="text-sm mb-10">
              {t('teachers.homework_solutions.add_feedback')}
            </div>
            <label className="font-extralight">{t('teachers.homework_solutions.inputs.teacher_review')}</label>
            <textarea
              rows={6}
              value={input.teacher_review}
              className='px-2 py-1 border border-gray-200 rounded-lg mt-3 mb-3 py-2 resize-none w-full lg:w-5/6 shadow-sm'
              onChange={(e) => { setInput((prevInput: any) => ({ ...prevInput, teacher_review: e.target.value })) }}
            />
            <div className="text-sm text-red-500 mt-3">
              {map(validationErrors.title, (error: string): string => `${t('teachers.homework_solutions.inputs.teacher_review')} ${error}`).join(', ')}
            </div>
            <label className="font-extralight">{t('teachers.homework_solutions.inputs.internal_teacher_review')}</label>
            <textarea
              rows={6}
              value={input.internal_teacher_review}
              className='px-2 py-1 border border-gray-200 rounded-lg mt-3 mb-3 py-2 resize-none w-full lg:w-5/6 shadow-sm'
              onChange={(e) => { setInput((prevInput: any) => ({ ...prevInput, internal_teacher_review: e.target.value })) }}
            />
            <div className="text-sm text-red-500 mt-3">
              {map(validationErrors.title, (error: string): string => `${t('teachers.homework_solutions.inputs.internal_teacher_review')} ${error}`).join(', ')}
            </div>
          </div>
        </div>
        <div className="lg:sticky lg:bottom-0 mb-28 lg:mb-0 lg:bg-white w-full py-4 lg:border-t lg:border-gray-300 flex gap-3 justify-end items-center px-4 lg:px-8">
          <button
            type="button"
            onClick={() => { window.history.back() }}
            className="text-blue-800 hover:text-white bg-white flex justify-center py-2 px-3 border border-blue-700 rounded-lg hover:bg-blue-700"
          >
            Anuluj
          </button>

          <button
            type="submit"
            className="bg-blue-500 content-center hover:bg-blue-700 text-white py-3 px-3 rounded-lg md:w-fit"
          >
            <div className="flex gap-1 justify-center">
              <span className="px-2">
                {t('teachers.homework_solutions.update')}
              </span>
            </div>
          </button>
        </div>
      </div>
    </form>
  )
}

export default TeacherHomeworkSolutionForm
