import React, { type ReactElement, useContext, useState, useEffect, useRef } from 'react'
import { MobileMenuContext } from '../../../contexts/common/MobileMenuContext'
import SearchBar from '../SearchBar'
import { API_SEARCHES_PATH } from '../../../constants/apiRoutes'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { CurrentUserContext } from '../../../contexts/users/CurrentUserContext'
import { DASHBOARD_PATH } from '../../../constants/rolePaths'

// FIXME: Translations
const FooterMobileNavbar = (): ReactElement => {
  const { currentUser } = useContext(CurrentUserContext)
  const { renderMobileNavbar, setRenderMobileMenu } = useContext(MobileMenuContext)
  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const { t } = useTranslation('translations')
  const searchBarRef = useRef<HTMLDivElement>(null)

  const handleOpenMobileMenu = (): void => {
    if (!renderMobileNavbar) setRenderMobileMenu(prev => !prev)
  }

  const handleSearchClick = (): void => {
    setIsSearchOpen(true)
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (isSearchOpen && searchBarRef.current && !searchBarRef.current.contains(event.target as Node)) {
        setIsSearchOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isSearchOpen])

  return (
    <div className="fixed bottom-0 left-0 right-0 h-[80px] flex items-center border-t border-gray-200 px-4 bg-white z-50">
      <div className="flex w-full justify-around text-sm text-blue-800">
        <div className="flex flex-col justify-center items-center" onClick={() => { handleSearchClick() }}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="blue" className="h-5 w-5">
            <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
          </svg>
          {t('navbar.search')}
        </div>
        <div className="flex flex-col justify-center items-center">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="blue" className="h-5 w-5">
            <path strokeLinecap="round" strokeLinejoin="round" d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
          </svg>
          {t('navbar.my_class_unit')}
        </div>
        <Link
          className="flex flex-col justify-center items-center"
          to={currentUser ? DASHBOARD_PATH[currentUser.role] : '/'}
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="blue" className="h-5 w-5">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
          </svg>
          {t('navbar.calendar')}
        </Link>
        <div
          className="flex flex-col justify-center items-center cursor-pointer"
          onClick={handleOpenMobileMenu}
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="blue" className="h-4 w-4">
            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5" />
          </svg>
          {t('navbar.menu')}
        </div>
      </div>
      {isSearchOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-start items-end pb-20 z-50">
          <div className="bg-white rounded-lg shadow-lg w-full" ref={searchBarRef}>
            <SearchBar apiFetchResultsUrl={API_SEARCHES_PATH} />
          </div>
        </div>
      )}
    </div>
  )
}

export default FooterMobileNavbar
