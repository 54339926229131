import React, { type ReactElement, useEffect, useState } from 'react'
import { map } from 'underscore'
import { ADMIN_LESSONS_PATH } from '../../../constants/adminRoutes'
import {
  API_ADMIN_SUBJECTS_PATH,
  API_ADMIN_TEACHERS_PATH,
  API_ADMIN_CLASS_UNITS_PATH,
  API_ADMIN_GROUPS_PATH
} from '../../../constants/apiRoutes'
import { useTranslation } from 'react-i18next'
import {
  type LessonData,
  type SubjectData,
  type TeacherData,
  type ClassUnitData,
  type GroupData
} from '../../../constants/types'
import { useApiFetch } from '../../../hooks/api/useApiFetch'
import { useApiPost } from '../../../hooks/api/useApiPost'

const LessonForm = ({ url, lesson, method = 'post' }: { url: string, lesson?: LessonData, method?: string }): ReactElement => {
  const { setInput, sendData, validationErrors, input } = useApiPost({ url, redirect: ADMIN_LESSONS_PATH, successFlashMessage: 'Pomyślnie zapisano' })
  const { t } = useTranslation('translations')

  const { data: subjects } = useApiFetch({ url: API_ADMIN_SUBJECTS_PATH })
  const { data: teachers } = useApiFetch({ url: API_ADMIN_TEACHERS_PATH })
  const { data: classUnits } = useApiFetch({ url: API_ADMIN_CLASS_UNITS_PATH })
  const { data: groups } = useApiFetch({ url: API_ADMIN_GROUPS_PATH })

  const [selectedClassUnits, setSelectedClassUnits] = useState<number[]>([])
  const [selectedGroups, setSelectedGroups] = useState<number[]>([])
  const [selectedTeachers, setSelectedTeachers] = useState<number[]>([])

  useEffect(() => {
    if (lesson) {
      setInput((_p: any) => {
        return {
          name: lesson.name,
          subject_id: lesson.subject?.id,
          teachers: map(lesson.teachers, (teacher) => teacher.id),
          class_units: lesson.class_units?.map(unit => unit.id),
          groups: lesson.groups?.map(group => group.id)
        }
      })
      setSelectedClassUnits(lesson.class_units ? lesson.class_units.map(unit => unit.id) : [])
      setSelectedGroups(lesson.groups ? lesson.groups.map(group => group.id) : [])
      setSelectedTeachers(map(lesson.teachers, (teacher) => teacher.id))
    }
  }, [lesson])

  const handleTeacherChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    const newSelectedOptions = Array.from(e.target.selectedOptions, option => parseInt(option.value))
    const updatedSelectedTeachers = Array.from(new Set([...selectedTeachers, ...newSelectedOptions])) // Changed from selectedClassUnits
    setSelectedTeachers(updatedSelectedTeachers)
    setInput((prevInput: any) => ({ ...prevInput, teachers: updatedSelectedTeachers }))
  }

  const removeTeacher = (teacherId: number): void => {
    const updatedIds = selectedTeachers.filter(id => id !== teacherId) // Changed from selectedClassUnits
    setSelectedTeachers(updatedIds)
    setInput((prevInput: any) => ({ ...prevInput, teachers: updatedIds }))
  }

  const handleClassUnitChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    const newSelectedOptions = Array.from(e.target.selectedOptions, option => parseInt(option.value))
    const updatedSelectedUnits = Array.from(new Set([...selectedClassUnits, ...newSelectedOptions]))
    setSelectedClassUnits(updatedSelectedUnits)
    setInput((prevInput: any) => ({ ...prevInput, class_units: updatedSelectedUnits }))
  }

  const removeClassUnit = (unitId: number): void => {
    const updatedIds = selectedClassUnits.filter(id => id !== unitId)
    setSelectedClassUnits(updatedIds)
    setInput((prevInput: any) => ({ ...prevInput, class_units: updatedIds }))
  }

  const handleGroupChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    const newSelectedOptions = Array.from(e.target.selectedOptions, option => parseInt(option.value))
    const updatedSelectedGroups = Array.from(new Set([...selectedGroups, ...newSelectedOptions]))
    setSelectedGroups(updatedSelectedGroups)
    setInput((prevInput: any) => ({ ...prevInput, groups: updatedSelectedGroups }))
  }

  const removeGroup = (groupId: number): void => {
    const updatedIds = selectedGroups.filter(id => id !== groupId)
    setSelectedGroups(updatedIds)
    setInput((prevInput: any) => ({ ...prevInput, groups: updatedIds }))
  }

  const saveLesson = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault()
    await sendData({ method })
  }

  return (
    <>
      <form onSubmit={saveLesson}>
        <div className="text-sm text-red-500">
          {map(validationErrors[''], (error: string): string => `${t('admin.lessons.lesson_error')} ${error}`).join(', ')}
        </div>
        <br />

        <label>{t('admin.lessons.attributes.name')}</label>
        <br />
        <input
          value={input.name}
          className="px-2 py-1 border rounded-md w-full"
          onChange={(e) => { setInput((prevInput: any) => ({ ...prevInput, name: e.target.value })) }}
        />
        <div className="text-sm text-red-500">
          {map(validationErrors.name, (error: string): string => `${t('admin.lessons.attributes.name')} ${error}`).join(', ')}
        </div>
        <br />

        <label>{t('admin.lessons.attributes.subject')}</label>
        <br />
        <select
          className="px-2 py-1 border rounded-md w-full"
          value={input.subject_id}
          onChange={(e) => { setInput((prevInput: any) => ({ ...prevInput, subject_id: e.target.value })) }}
        >
          <option value="">{t('admin.lessons.subject_placeholder')}</option>
          {
            map(subjects, (subject: SubjectData) => {
              return (
                <option
                  key={subject.id}
                  value={subject.id}
                >
                  {subject.name}
                </option>
              )
            })
          }
        </select>
        <div className="text-sm text-red-500">
          {map(validationErrors.subject_id, (error: string): string => `${t('admin.lessons.attributes.subject')} ${error}`).join(', ')}
        </div>
        <br />

        <label>{t('admin.lessons.attributes.teachers')}</label>
        <br />
        <select
          multiple
          className="px-2 py-1 border rounded-md w-full"
          value={selectedTeachers.map(String)}
          onChange={handleTeacherChange}
        >
          {
            map(teachers, (teacher: TeacherData) => {
              return (
                <option
                  key={teacher.id}
                  value={teacher.id}
                >
                  {teacher.user?.name}
                </option>
              )
            })
          }
        </select>
        <div>
          {selectedTeachers.map(id => (
            <div key={id} className="flex justify-between items-center">
              <span>{teachers?.find((teacher: TeacherData) => teacher.id === id)?.user?.name}</span>
              <button type="button" onClick={() => { removeTeacher(id) }} className="bg-red-500 text-white p-1 rounded">Remove</button>
            </div>
          ))}
        </div>
        <div className="text-sm text-red-500">
          {map(validationErrors.teachers, (error: string): string => `${t('admin.lessons.attributes.teachers')} ${error}`).join(', ')}
        </div>
        <br />

        <label>{t('admin.lessons.attributes.class_units')}</label>
        <br />
        <select
          multiple
          className="px-2 py-1 border rounded-md w-full"
          value={selectedClassUnits.map(String)}
          onChange={handleClassUnitChange}
        >
          {map(classUnits, (classUnit: ClassUnitData) => (
            <option key={classUnit.id} value={classUnit.id.toString()}>{classUnit.name}</option>
          ))}
        </select>
        <div>
          {selectedClassUnits.map(id => (
            <div key={id} className="flex justify-between items-center">
              <span>{classUnits?.find((unit: ClassUnitData) => unit.id === id)?.name}</span>
              <button type="button" onClick={() => { removeClassUnit(id) }} className="bg-red-500 text-white p-1 rounded">Remove</button>
            </div>
          ))}
        </div>
        <div className="text-sm text-red-500">
          {map(validationErrors.class_units, (error) => `${t('admin.lessons.attributes.class_units')} ${error}`).join(', ')}
        </div>
        <br />

        <label>{t('admin.lessons.attributes.groups')}</label>
        <br />
        <select
          multiple
          className="px-2 py-1 border rounded-md w-full"
          value={selectedGroups.map(String)}
          onChange={handleGroupChange}
        >
          {map(groups, (group: GroupData) => (
            <option key={group.id} value={group.id.toString()}>{group.name}</option>
          ))}
        </select>
        <div>
          {selectedGroups.map(id => (
            <div key={id} className="flex justify-between items-center">
              <span>{groups?.find((group: GroupData) => group.id === id)?.name}</span>
              <button type="button" onClick={() => { removeGroup(id) }} className="bg-red-500 text-white p-1 rounded">Remove</button>
            </div>
          ))}
        </div>
        <div className="text-sm text-red-500">
          {map(validationErrors.groups, (error) => `${t('admin.lessons.attributes.groups')} ${error}`).join(', ')}
        </div>
        <br />

        <div className="flex justify-center pt-3">
          <button type="submit" className="bg-blue-400 rounded-md text-white p-2">
            {t('admin.lessons.submit_button')}
          </button>
        </div>
      </form>
    </>
  )
}

export default LessonForm
