import React, { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import LessonScheduleImportForm from '../../../components/admin/lesson_schedule_imports/LessonScheduleImportForm'
import { API_ADMIN_LESSON_SCHEDULE_IMPORTS_PATH } from '../../../constants/apiRoutes'

const NewLessonScheduleImportView = (): ReactElement => {
  const { t } = useTranslation('translations')

  return (
    <div className="grid w-full" >
      <div className="px-5 lg:px-14 py-6">
        <h1 className="text-2xl lg:text-3xl text-left pb-12">{t('admin.lesson_schedule_imports.new_header')}</h1>
        <div className="flex items-center justify-center w-full">
          <LessonScheduleImportForm url={API_ADMIN_LESSON_SCHEDULE_IMPORTS_PATH} />
        </div>
      </div>
    </div>
  )
}

export default NewLessonScheduleImportView
