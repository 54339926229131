import React, { type ReactElement, useEffect, useState } from 'react'
import { type SelectOptions } from '../../constants/types'
import { map, isEmpty } from 'underscore'
import { renderToString } from 'react-dom/server'
import HSSelect from '@preline/select'

const MultiSelect = ({
  options,
  search = false,
  placeholder,
  onChange,
  id,
  className,
  toggleClasses,
  defaultSelected = [],
  dropdownClasses
}: {
  options: SelectOptions[]
  search?: boolean
  placeholder?: string
  onChange: (e: string) => any
  id: string
  className?: string
  toggleClasses?: string
  defaultSelected?: string[] | null
  dropdownClasses?: string
}): ReactElement => {
  const [isOpen, setIsOpen] = useState<Record<string, boolean>>({})
  const [initialized, setInitialized] = useState(false)

  const selectOptions = {
    placeholder: placeholder ?? 'Wybierz opcję...',
    hasSearch: search,
    searchClasses: 'block w-full text-sm border-gray-200 rounded-lg focus:border-blue-500 focus:ring-blue-500 before:absolute before:inset-0 before:z-[1] py-2 px-3 border rounded-lg',
    searchWrapperClasses: 'bg-white p-2 -mx-1 sticky top-0',
    toggleTag: renderToString(<ToggleTag />),
    optionTemplate: renderToString(<OptionTemplate />),
    toggleClasses: `${toggleClasses ?? 'py-3'} hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative shadow-md ps-4 pe-9 flex gap-x-2 text-nowrap w-full cursor-pointer bg-white border border-gray-300 rounded-lg text-start text-sm focus:outline-none`,
    dropdownClasses: `${dropdownClasses} mt-2 z-[100] max-h-72 w-fit p-1 space-y-0.5 bg-white shadow-md border border-gray-200 rounded-lg overflow-hidden overflow-y-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300`,
    optionClasses: 'py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50'
  }

  useEffect(() => {
    const element = document.querySelector(`#${id}`) as HTMLElement

    if (element) {
      HSSelect.autoInit()

      if (!initialized && defaultSelected && defaultSelected.length > 0) {
        const selectInstance = HSSelect.getInstance(element) as HSSelect
        if (selectInstance) {
          selectInstance.setValue(defaultSelected)
          onChange(defaultSelected.join(','))
          setInitialized(true)
        }
      }

      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
            const classList = (mutation.target as Element).classList
            setIsOpen((prev) => ({
              ...prev,
              [id]: classList.contains('opened')
            }))
          }
        })
      })

      observer.observe(element, { attributes: true })

      return () => {
        if (element) observer.disconnect()
      }
    }
  }, [options, id, defaultSelected, initialized])

  const handleToggleClick = (): void => {
    const element = document.querySelector(`#${id}`) as HTMLElement
    const selectInstance = HSSelect.getInstance(element) as HSSelect

    if (isOpen[id]) {
      selectInstance.close()
    } else {
      selectInstance.open()
    }

    setIsOpen((prev) => ({
      ...prev,
      [id]: !prev[id]
    }))
  }

  const handleChange = (): void => {
    const element = document.querySelector(`#${id}`) as HTMLElement
    const selectInstance = HSSelect.getInstance(element) as HSSelect
    const selectInstanceValue = selectInstance.value as string[]

    onChange(selectInstanceValue.join(','))
  }

  // We need to check if options are empty because preline select can't load before options
  return (
    <div className={className ?? 'w-[175px]'}>
      <div
        className="relative"
        onClick={handleToggleClick}
      >
        {!isEmpty(options) && (
          <select
            multiple={true}
            data-hs-select={JSON.stringify(selectOptions)}
            className="hidden w-48"
            id={id}
            onChange={handleChange}
            defaultValue={defaultSelected as string[]}
          >
            {map(options, (option: SelectOptions) => {
              return <option
                key={option.value}
                value={option.value}
                disabled={option.disabled}
              >
                {option.text}
              </option>
            })}
          </select>
        )}
        <ExtraMarkup isOpen={isOpen[id]} />
      </div>
    </div>
  )
}

const OptionTemplate = (): ReactElement => {
  return (
    <div className="flex justify-between items-center w-full">
      <span data-title />
      <span className="hidden hs-selected:block">
        <svg className="shrink-0 size-3.5 text-blue-600"
          xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
          fill="none" stroke="currentColor" strokeWidth="2"
          strokeLinecap="round" strokeLinejoin="round">
          <polyline points="20 6 9 17 4 12"/>
        </svg>
      </span>
    </div>
  )
}

const ExtraMarkup = ({ isOpen }: { isOpen: boolean }): ReactElement => {
  return (
    <div className={`absolute top-1/2 right-3 -translate-y-1/2 hover:cursor-pointer transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-6 w-6">
        <path fillRule="evenodd" d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
      </svg>
    </div>
  )
}

const ToggleTag = (): ReactElement => {
  return (
    <button type="button" aria-expanded="false" />
  )
}

export default MultiSelect
