import React, { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useApiFetch } from '../../../hooks/api/useApiFetch'
import { map } from 'underscore'
import { Link } from 'react-router-dom'
import { API_ADMIN_TEACHERS_PATH } from '../../../constants/apiRoutes'
import { ADMIN_NEW_TEACHER_PATH } from '../../../constants/adminRoutes'
import { type TeacherData } from '../../../constants/types'
import Plus from '../../../assets/icons/plus.svg'
import Pagination from '../../../components/common/Pagination'
import TeacherListItem from '../../../components/admin/teachers/TeacherListItem'
import TeachersFilters from '../../../components/admin/teachers/TeachersFilters'

const TeachersView = (): ReactElement => {
  const apiUrl = `${API_ADMIN_TEACHERS_PATH}?paginated=true`
  const { data: teachers, fetchData, query, setQuery } = useApiFetch({ url: apiUrl, paginated: true })
  const { t } = useTranslation('translations')

  return (
    <div className="w-full px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto pb-6 pt-6 sm:pb-8 sm:pt-8">
        <div className="flex flex-col sm:flex-row justify-between gap-4 pb-6">
          <div className="text-xl font-bold font-['Inter'] leading-tight">
            {t('admin.teachers.h1')}
          </div>

          <div className="flex flex-col sm:flex-row gap-4 items-start sm:items-center">
            <TeachersFilters fetchTeachers={fetchData} query={query} setQuery={setQuery} />

            <Link
              to={ADMIN_NEW_TEACHER_PATH}
              className="inline-flex items-center pl-4 py-2 bg-blue-500 hover:bg-blue-700 rounded text-white whitespace-nowrap"
            >
              {t('admin.teachers.add_button')}
              <img src={Plus} alt="plus.svg" className="mx-4 w-4 h-4" />
            </Link>
          </div>
        </div>

        <div className="bg-white shadow rounded-lg overflow-hidden">
          <div className="hidden lg:grid lg:grid-cols-12 bg-[#F9FAFB] py-4 px-4">
            <div className="col-span-1" />
            <div className="col-span-3 font-['Inter']">{t('admin.teachers.attributes.name')}</div>
            <div className="col-span-3 font-['Inter']">{t('admin.teachers.attributes.email')}</div>
            <div className="col-span-2 font-['Inter'] hidden lg:block">{t('admin.teachers.attributes.created_at')}</div>
            <div className="col-span-3 font-['Inter']" />
          </div>

          <div className="divide-y divide-gray-200">
            {map(teachers?.data, (teacher: TeacherData): ReactElement => (
              <TeacherListItem teacher={teacher} key={teacher.id} fetchData={fetchData} />
            ))}
          </div>

          <div className="border-t border-gray-200">
            <div className="px-4 py-4">
              <Pagination paginationMeta={teachers?.meta} fetchData={fetchData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TeachersView
