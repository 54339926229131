import React, { type ReactElement } from 'react'

const EmptySearchTableBody = (): ReactElement => {
  return (
    <div className="flex justify-center w-full bg-white">
      <div className="flex flex-col justify-center items-center text-center min-h-[400px] w-full">
        <svg className="w-48 mx-auto mb-4" width="87" height="65" viewBox="0 0 87 65" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect y="35" width="6" height="30" fill="currentColor" className="fill-gray-200 dark:fill-neutral-700"/>
          <rect x="9" y="20" width="6" height="45" fill="currentColor" className="fill-gray-200 dark:fill-neutral-700"/>
          <rect x="18" y="25" width="6" height="40" fill="currentColor" className="fill-gray-200 dark:fill-neutral-700"/>
          <rect x="27" width="6" height="65" fill="currentColor" className="fill-gray-200 dark:fill-neutral-700"/>
          <rect x="36" y="5" width="6" height="60" fill="currentColor" className="fill-gray-200 dark:fill-neutral-700"/>
          <rect x="45" y="40" width="6" height="25" fill="currentColor" className="fill-gray-200 dark:fill-neutral-700"/>
          <rect x="54" y="25" width="6" height="40" fill="currentColor" className="fill-gray-200 dark:fill-neutral-700"/>
          <rect x="63" y="12" width="6" height="53" fill="currentColor" className="fill-gray-200 dark:fill-neutral-700"/>
          <rect x="72" width="6" height="65" fill="currentColor" className="fill-gray-200 dark:fill-neutral-700"/>
          <rect x="81" y="44" width="6" height="21" fill="currentColor" className="fill-gray-200 dark:fill-neutral-700"/>
        </svg>

        <div className="max-w-sm mx-auto">
          <p className="mt-2 font-medium text-gray-800 dark:text-neutral-200">
            Nie znaleziono wyników
          </p>
          <p className="text-sm text-gray-500 dark:text-neutral-500">
            Spróbuj roszerzyć swój zakres wyszukiwania
          </p>
        </div>
      </div>
    </div>
  )
}

export default EmptySearchTableBody
