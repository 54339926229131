import React, { type ReactElement } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { Provider as RollbarProvider } from '@rollbar/react'
import CurrentUserContextProvider from './contexts/users/CurrentUserContextProvider'
import FlashContextProvider from './contexts/flash/FlashContextProvider'
import MobileMenuContextProvider from './contexts/common/MobileMenuContextProvider'

// COMMON
import Layout from './components/common/Layout'
import NotFoundView from './views/errors/NotFoundView'
import LoginView from './views/LoginView'
import ForgotPasswordView from './views/ForgotPasswordView'
import ResetPasswordView from './views/ResetPasswordView'
import MessagesView from './views/MessagesView'
import NewsView from './views/NewsView'
import NewsDetailsView from './views/NewsDetailsView'
import UserProfileView from './views/users/UserProfileView'
import EditUserProfileView from './views/users/EditUserProfileView'
import ClassUnitView from './views/ClassUnitView'
import AdvancedSearchingView from './views/AdvancedSearchingView'

import {
  LOGIN_PATH,
  FORGOT_PASSWORD_PATH,
  RESET_PASSWORD_PATH,
  MESSAGES_PATH,
  NEWS_PATH,
  NEWS_DETAILS_VIEW,
  USER_PROFILE_PATH,
  CLASS_UNIT_PATH,
  ADVANCED_SEARCHING_PATH,
  EDIT_USER_PROFILE_PATH
} from './constants/routes'

// STUDENTS
import StudentDashboardView from './views/students/StudentDashboardView'
import StudentHomeworkListView from './views/students/StudentHomeworkListView'
import StudentHomeworkSolutionEditView from './views/students/StudentHomeworkSolutionEditView'
import StudentNewHomeworkSolutionView from './views/students/StudentNewHomeworkSolutionView'
import StudentHomeworkSolutionView from './views/students/StudentHomeworkSolutionView'
import StudentGradesView from './views/students/StudentGradesView'
import StudentLessonListView from './views/students/StudentLessonListView'
import StudentLessonView from './views/students/StudentLessonView'

import {
  STUDENTS_NAMESPACE_PATH,
  STUDENT_DASHBOARD_PATH,
  STUDENT_HOMEWORKS_PATH,
  STUDENT_HOMEWORK_SOLUTION_EDIT_PATH,
  STUDENT_HOMEWORK_SOLUTION_PATH,
  STUDENT_NEW_HOMEWORK_SOLUTION_PATH,
  STUDENT_GRADES_PATH,
  STUDENT_LESSONS_PATH,
  STUDENT_LESSON_PATH
} from './constants/studentRoutes'

// TEACHERS
import TeacherDashboardView from './views/teachers/TeacherDashboardView'
import TeacherHomeworkListView from './views/teachers/TeacherHomeworkListView'
import TeacherLessonHomeworkListView from './views/teachers/TeacherLessonHomeworkListView'
import TeacherNewHomeworkView from './views/teachers/TeacherNewHomeworkView'
import TeacherHomeworkEditView from './views/teachers/TeacherHomeworkEditView'
import TeacherHomeworkSolutionEditView from './views/teachers/TeacherHomeworkSolutionEditView'
import TeacherHomeworkView from './views/teachers/TeacherHomeworkView'
import TeacherLessonListView from './views/teachers/TeacherLessonListView'
import TeacherLessonView from './views/teachers/TeacherLessonView'

import {
  TEACHERS_NAMESPACE_PATH,
  TEACHER_DASHBOARD_PATH,
  TEACHER_HOMEWORKS_PATH,
  TEACHER_LESSON_HOMEWORKS_PATH,
  TEACHER_HOMEWORK_PATH,
  TEACHER_NEW_HOMEWORK_PATH,
  TEACHER_HOMEWORK_EDIT_PATH,
  TEACHER_HOMEWORK_SOLUTION_EDIT_PATH,
  TEACHER_LESSONS_PATH,
  TEACHER_LESSON_PATH
} from './constants/teacherRoutes'

// PARENTS
import ParentDashboardView from './views/parents/ParentDashboardView'

import {
  PARENT_DASHBOARD_PATH,
  PARENT_NAMESPACE_PATH
} from './constants/parentRoutes'

// ADMIN
import AdminDashboardView from './views/admin/AdminDashboardView'
import AdminLayout from './components/common/admin/Layout'
import AdminNewsView from './views/admin/news/NewsView'
import AdminEditNewsView from './views/admin/news/EditNewsView'
import AdminNewNewsView from './views/admin/news/NewNewsView'
import AdminCalendarEventsListView from './views/admin/calendar_events/CalendarEventsListView'
import AdminEditCalendarEventView from './views/admin/calendar_events/EditCalendarEventView'
import AdminNewCalendarEventView from './views/admin/calendar_events/NewCalendarEventView'
import AdminDocumentsView from './views/admin/documents/DocumentsView'
import AdminNewDocumentView from './views/admin/documents/NewDocumentView'
import AdminEditDocumentView from './views/admin/documents/EditDocumentView'
import AdminClassroomsView from './views/admin/classrooms/ClassroomsView'
import AdminNewClassroomView from './views/admin/classrooms/NewClassroomView'
import AdminEditClassroomView from './views/admin/classrooms/EditClassroomView'
import AdminLessonSlotsView from './views/admin/lesson_slots/LessonSlotsView'
import AdminNewLessonSlotView from './views/admin/lesson_slots/NewLessonSlotView'
import AdminEditLessonSlotView from './views/admin/lesson_slots/EditLessonSlotView'
import AdminLessonsView from './views/admin/lessons/LessonsView'
import AdminNewLessonView from './views/admin/lessons/NewLessonView'
import AdminEditLessonView from './views/admin/lessons/EditLessonView'
import AdminTeachersView from './views/admin/teachers/TeachersView'
import AdminNewTeacherView from './views/admin/teachers/NewTeacherView'
import AdminSubjectsView from './views/admin/subjects/SubjectsView'
import AdminNewSubjectView from './views/admin/subjects/NewSubjectView'
import AdminEditSubjectView from './views/admin/subjects/EditSubjectView'
import AdminLessonScheduleImportsView from './views/admin/lesson_schedule_imports/LessonScheduleImportsView'
import AdminNewLessonScheduleImportView from './views/admin/lesson_schedule_imports/NewLessonScheduleImportView'
import AdminLessonScheduleImportDetailsView from './views/admin/lesson_schedule_imports/LessonScheduleImportDetailsView'
import AdminLessonSchedulesView from './views/admin/lesson_schedules/LessonSchedulesView'

import {
  ADMIN_NAMESPACE_PATH,
  ADMIN_DASHBOARD_PATH,
  ADMIN_NEWS_PATH,
  ADMIN_EDIT_NEWS_PATH,
  ADMIN_NEW_NEWS_PATH,
  ADMIN_CALENDAR_EVENTS_PATH,
  ADMIN_EDIT_CALENDAR_EVENT_PATH,
  ADMIN_NEW_CALENDAR_EVENT_PATH,
  ADMIN_DOCUMENTS_PATH,
  ADMIN_NEW_DOCUMENT_PATH,
  ADMIN_EDIT_DOCUMENT_PATH,
  ADMIN_TEACHERS_PATH,
  ADMIN_NEW_TEACHER_PATH,
  ADMIN_LESSON_SLOTS_PATH,
  ADMIN_NEW_LESSON_SLOT_PATH,
  ADMIN_EDIT_LESSON_SLOT_PATH,
  ADMIN_CLASSROOMS_PATH,
  ADMIN_NEW_CLASSROOM_PATH,
  ADMIN_EDIT_CLASSROOM_PATH,
  ADMIN_LESSONS_PATH,
  ADMIN_NEW_LESSON_PATH,
  ADMIN_SUBJECTS_PATH,
  ADMIN_NEW_SUBJECT_PATH,
  ADMIN_EDIT_SUBJECT_PATH,
  ADMIN_EDIT_LESSON_PATH,
  ADMIN_LESSON_SCHEDULE_IMPORTS_PATH,
  ADMIN_NEW_LESSON_SCHEDULE_IMPORT_PATH,
  ADMIN_EDIT_LESSON_SCHEDULE_IMPORT_PATH,
  ADMIN_LESSON_SCHEDULES_PATH
} from './constants/adminRoutes'

import { I18nextProvider } from 'react-i18next'
import i18next from 'i18next'
import translations_pl from './translations/pl/translations.json'

void i18next.init(
  {
    interpolation: { escapeValue: false },
    lng: 'pl',
    resources: {
      pl: {
        translations: translations_pl
      }
    }
  }
)

const App = (): ReactElement => {
  const rollbarConfig = {
    accessToken: process.env.REACT_APP_POST_CLIENT_ITEM_ACCESS_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: ['production', 'staging'].includes(process.env.NODE_ENV as string),
    payload: {
      client: {
        javascript: {
          code_version: '1.0.0',
          source_map_enabled: true
        }
      }
    }
  }

  const router = createBrowserRouter([
    {
      element: <Layout />,
      errorElement: <NotFoundView />,
      children: [
        {
          path: '',
          element: <LoginView />
        },
        {
          path: LOGIN_PATH,
          element: <LoginView />
        },
        {
          path: FORGOT_PASSWORD_PATH,
          element: <ForgotPasswordView />
        },
        {
          path: RESET_PASSWORD_PATH,
          element: <ResetPasswordView />
        },
        {
          path: MESSAGES_PATH,
          element: <MessagesView />
        },
        {
          path: NEWS_PATH,
          element: <NewsView />
        },
        {
          path: NEWS_DETAILS_VIEW,
          element: <NewsDetailsView />
        },
        {
          path: USER_PROFILE_PATH,
          element: <UserProfileView />
        },
        {
          path: EDIT_USER_PROFILE_PATH,
          element: <EditUserProfileView />
        },
        {
          path: CLASS_UNIT_PATH,
          element: <ClassUnitView />
        },
        {
          path: ADVANCED_SEARCHING_PATH,
          element: <AdvancedSearchingView />
        },
        {
          path: STUDENTS_NAMESPACE_PATH,
          children: [
            {
              path: STUDENT_DASHBOARD_PATH,
              element: <StudentDashboardView />
            },
            {
              path: STUDENT_HOMEWORKS_PATH,
              element: <StudentHomeworkListView />
            },
            {
              path: STUDENT_HOMEWORK_SOLUTION_EDIT_PATH,
              element: <StudentHomeworkSolutionEditView />
            },
            {
              path: STUDENT_NEW_HOMEWORK_SOLUTION_PATH,
              element: <StudentNewHomeworkSolutionView />
            },
            {
              path: STUDENT_HOMEWORK_SOLUTION_PATH,
              element: <StudentHomeworkSolutionView />
            },
            {
              path: STUDENT_GRADES_PATH,
              element: <StudentGradesView />
            },
            {
              path: STUDENT_LESSONS_PATH,
              element: <StudentLessonListView />
            },
            {
              path: STUDENT_LESSON_PATH,
              element: <StudentLessonView />
            }
          ]
        },
        {
          path: TEACHERS_NAMESPACE_PATH,
          children: [
            {
              path: TEACHER_DASHBOARD_PATH,
              element: <TeacherDashboardView />
            },
            {
              path: TEACHER_HOMEWORKS_PATH,
              element: <TeacherHomeworkListView />
            },
            {
              path: TEACHER_LESSON_HOMEWORKS_PATH,
              element: <TeacherLessonHomeworkListView />
            },
            {
              path: TEACHER_NEW_HOMEWORK_PATH,
              element: <TeacherNewHomeworkView />
            },
            {
              path: TEACHER_HOMEWORK_EDIT_PATH,
              element: <TeacherHomeworkEditView />
            },
            {
              path: TEACHER_HOMEWORK_PATH,
              element: <TeacherHomeworkView />
            },
            {
              path: TEACHER_HOMEWORK_SOLUTION_EDIT_PATH,
              element: <TeacherHomeworkSolutionEditView />
            },
            {
              path: TEACHER_LESSONS_PATH,
              element: <TeacherLessonListView />
            },
            {
              path: TEACHER_LESSON_PATH,
              element: <TeacherLessonView />
            }
          ]
        },
        {
          path: PARENT_NAMESPACE_PATH,
          children: [
            {
              path: PARENT_DASHBOARD_PATH,
              element: <ParentDashboardView />
            }
          ]
        },
        {
          path: ADMIN_NAMESPACE_PATH,
          element: <AdminLayout />,
          children: [
            {
              path: '',
              element: <AdminDashboardView />
            },
            {
              path: ADMIN_DASHBOARD_PATH,
              element: <AdminDashboardView />
            },
            {
              path: ADMIN_NEWS_PATH,
              element: <AdminNewsView />
            },
            {
              path: ADMIN_NEW_NEWS_PATH,
              element: <AdminNewNewsView />
            },
            {
              path: ADMIN_EDIT_NEWS_PATH,
              element: <AdminEditNewsView />
            },
            {
              path: ADMIN_CALENDAR_EVENTS_PATH,
              element: <AdminCalendarEventsListView />
            },
            {
              path: ADMIN_NEW_CALENDAR_EVENT_PATH,
              element: <AdminNewCalendarEventView />
            },
            {
              path: ADMIN_EDIT_CALENDAR_EVENT_PATH,
              element: <AdminEditCalendarEventView />
            },
            {
              path: ADMIN_DOCUMENTS_PATH,
              element: <AdminDocumentsView />
            },
            {
              path: ADMIN_NEW_DOCUMENT_PATH,
              element: <AdminNewDocumentView />
            },
            {
              path: ADMIN_EDIT_DOCUMENT_PATH,
              element: <AdminEditDocumentView />
            },
            {
              path: ADMIN_TEACHERS_PATH,
              element: <AdminTeachersView />
            },
            {
              path: ADMIN_NEW_TEACHER_PATH,
              element: <AdminNewTeacherView />
            },
            {
              path: ADMIN_CLASSROOMS_PATH,
              element: <AdminClassroomsView />
            },
            {
              path: ADMIN_NEW_CLASSROOM_PATH,
              element: <AdminNewClassroomView />
            },
            {
              path: ADMIN_EDIT_CLASSROOM_PATH,
              element: <AdminEditClassroomView />
            },
            {
              path: ADMIN_LESSON_SLOTS_PATH,
              element: <AdminLessonSlotsView />
            },
            {
              path: ADMIN_LESSON_SCHEDULE_IMPORTS_PATH,
              element: <AdminLessonScheduleImportsView />
            },
            {
              path: ADMIN_NEW_LESSON_SCHEDULE_IMPORT_PATH,
              element: <AdminNewLessonScheduleImportView />
            },
            {
              path: ADMIN_EDIT_LESSON_SCHEDULE_IMPORT_PATH,
              element: <AdminLessonScheduleImportDetailsView />
            },
            {
              path: ADMIN_LESSON_SCHEDULES_PATH,
              element: <AdminLessonSchedulesView />
            },
            {
              path: ADMIN_NEW_LESSON_SLOT_PATH,
              element: <AdminNewLessonSlotView />
            },
            {
              path: ADMIN_EDIT_LESSON_SLOT_PATH,
              element: <AdminEditLessonSlotView />
            },
            {
              path: ADMIN_LESSONS_PATH,
              element: <AdminLessonsView />
            },
            {
              path: ADMIN_SUBJECTS_PATH,
              element: <AdminSubjectsView />
            },
            {
              path: ADMIN_NEW_SUBJECT_PATH,
              element: <AdminNewSubjectView />
            },
            {
              path: ADMIN_EDIT_SUBJECT_PATH,
              element: <AdminEditSubjectView />
            },
            {
              path: ADMIN_NEW_LESSON_PATH,
              element: <AdminNewLessonView />
            },
            {
              path: ADMIN_EDIT_LESSON_PATH,
              element: <AdminEditLessonView />
            }
          ]
        }
      ]
    }
  ])

  return (
    <RollbarProvider config={rollbarConfig}>
      <I18nextProvider i18n={i18next}>
        <CurrentUserContextProvider>
          <FlashContextProvider>
            <MobileMenuContextProvider>
              <RouterProvider router={router} />
            </MobileMenuContextProvider>
          </FlashContextProvider>
        </CurrentUserContextProvider>
      </I18nextProvider>
    </RollbarProvider>
  )
}

export default App
