import React, { useState, type ReactElement } from 'react'
import ReactCrop, { type Crop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { useTranslation } from 'react-i18next'
import DefaultAvatar from '../../assets/default_avatar.svg'

const ProfileAvatarEditor = ({
  setInput,
  currentAvatar
}: {
  setInput: (fn: (prev: any) => any) => void
  currentAvatar?: string
}): ReactElement => {
  const { t } = useTranslation('translations')
  const [crop, setCrop] = useState<Crop>({
    unit: 'px',
    x: 0,
    y: 0,
    width: 100,
    height: 100
  })
  const [, setCompletedCrop] = useState<Crop | null>(null)
  const [uploadedFile, setUploadedFile] = useState<File | null>(null)

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const file = event.target.files?.[0]
    if (file) {
      setUploadedFile(file)
      setInput((prevInput: any) => ({
        ...prevInput,
        avatar: file,
        avatar_crop_x: 0,
        avatar_crop_y: 0,
        avatar_crop_width: 100,
        avatar_crop_height: 100
      }))
    }
  }

  const handleCropComplete = (crop: Crop): void => {
    setCompletedCrop(crop)
    setInput((prevInput: any) => ({
      ...prevInput,
      avatar_crop_x: Math.round(crop.x),
      avatar_crop_y: Math.round(crop.y),
      avatar_crop_width: Math.round(crop.width),
      avatar_crop_height: Math.round(crop.height)
    }))
  }

  const renderAvatar = (): ReactElement => {
    if (uploadedFile) {
      return (
        <ReactCrop
          crop={crop}
          onChange={(newCrop) => { setCrop(newCrop) }}
          onComplete={handleCropComplete}
          aspect={1}
        >
          <img
            src={URL.createObjectURL(uploadedFile)}
            crossOrigin="anonymous"
            alt="New Avatar"
            className="w-64 h-64 object-contain"
          />
        </ReactCrop>
      )
    } else if (currentAvatar) {
      return (
        <ReactCrop
          crop={crop}
          onChange={(newCrop) => { setCrop(newCrop) }}
          onComplete={handleCropComplete}
          aspect={1}
        >
          <img
            src={currentAvatar}
            crossOrigin="anonymous"
            alt="Current Avatar"
            className="w-64 h-64 object-contain"
          />
        </ReactCrop>
      )
    } else {
      return (
        <img
          src={DefaultAvatar}
          crossOrigin="anonymous"
          alt="Default Avatar"
          className="w-64 h-64 object-contain"
        />
      )
    }
  }

  return (
    <div className="mb-6">
      <div className="border border-gray-200 rounded-lg p-4">
        <div className="mb-4 flex justify-center">
          {renderAvatar()}
        </div>

        <div className="flex flex-col gap-2">
          <label className="inline-block px-6 py-2 bg-blue-500 text-white font-semibold rounded-lg cursor-pointer hover:bg-blue-600 transition-colors text-center">
            {t('users.profile.replace_avatar')}
            <input
              type="file"
              accept="image/*"
              onChange={onFileChange}
              className="hidden"
            />
          </label>
        </div>
      </div>
    </div>
  )
}

export default ProfileAvatarEditor
