import React, { type ReactElement, useState, useEffect } from 'react'
import { type CurrentUserData } from '../../constants/types'
import { CurrentUserContext } from './CurrentUserContext'
import { LOGIN_PATH } from '../../constants/routes'
import { useApiFetch } from '../../hooks/api/useApiFetch'
import { API_USERS_PROFILE_PATH } from '../../constants/apiRoutes'
import { jwtDecode } from 'jwt-decode'

const CurrentUserContextProvider = ({ children }: { children: ReactElement }): ReactElement => {
  const [currentUser, setCurrentUser] = useState<CurrentUserData | null>(null)

  const fetchCurrentUserId = (): string | null => {
    try {
      const token = localStorage.getItem('access_token')
      if (!token) {
        console.warn('Access token is missing')
        return null
      }

      const decodedToken = (token ? jwtDecode(token) : null) as CurrentUserData
      return decodedToken?.user_id?.toString() ?? null
    } catch (error) {
      console.error('Failed to decode token:', error)
      return null
    }
  }

  const userId = fetchCurrentUserId()

  const { fetchData } = useApiFetch({
    url: userId ? API_USERS_PROFILE_PATH.replace(':id', userId) : '',
    onRender: false
  })

  const logout = (): void => {
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    window.location.href = LOGIN_PATH
  }

  const fetchCurrentUser = async (): Promise<void> => {
    if (!userId) {
      console.warn('Cannot fetch current user without a valid user ID.')
      return
    }

    try {
      const response = await fetchData()
      setCurrentUser({
        user_id: response.id,
        role: response.role,
        name: response.name,
        avatar_url: response.avatar?.url,
        admin: response.admin
      })
    } catch (error) {
      console.error('Failed to fetch current user:', error)
    }
  }

  useEffect(() => {
    if (userId) {
      fetchCurrentUser().catch(console.error)
    }
  }, [userId])

  return (
    <CurrentUserContext.Provider value={{ currentUser, setCurrentUser, logout, fetchCurrentUser }}>
      {children}
    </CurrentUserContext.Provider>
  )
}

export default CurrentUserContextProvider
