import React, { type ReactElement, useEffect } from 'react'
import { isEmpty, map, omit, isNull } from 'underscore'
import { useApiPost } from '../../../hooks/api/useApiPost'
import { ADMIN_NEWS_PATH } from '../../../constants/adminRoutes'
import { API_ADMIN_NEWS_UPLOADS_PATH, API_ADMIN_ROLES_PATH } from '../../../constants/apiRoutes'
import { useTranslation } from 'react-i18next'
import { type RoleData, type NewsData } from '../../../constants/types'
import RichTextEditor from '../../common/RichTextEditor'
import MultiSelect from '../../common/MultiSelect'
import { useApiFetch } from '../../../hooks/api/useApiFetch'

const NewsForm = ({ url, news, method = 'post' }: { url: string, news?: NewsData | null, method?: string }): ReactElement => {
  const { setInput, sendData, validationErrors, input } = useApiPost({ url, redirect: ADMIN_NEWS_PATH, successFlashMessage: 'Pomyślnie zapisano' })
  const { data: roles } = useApiFetch({ url: API_ADMIN_ROLES_PATH })
  const { t } = useTranslation('translations')

  useEffect(() => {
    const expiresAt = news?.expires_at ?? endOfSemesterDate()
    const notNullParams = omit(news, isNull)
    const requiredToRead = news?.required_to_read ?? false
    setInput((_p: any) => ({ ...notNullParams, required_to_read: requiredToRead, expires_at: expiresAt }))
  }, [news])

  const endOfSemesterDate = (): string => {
    const currentDate = new Date()
    const currentYear = currentDate.getFullYear()
    const august31stCurrentYear = new Date(currentYear, 7, 31)

    return (currentDate > august31stCurrentYear ? new Date(currentYear + 1, 7, 31) : august31stCurrentYear).toISOString().slice(0, 10)
  }

  const saveNews = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault()
    await sendData({ method })
  }

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>, key: string): void => {
    const value = isEmpty(e.target.value) ? null : e.target.value
    setInput((prevInput: any) => ({ ...prevInput, [key]: value }))
  }

  return (
    <>
      <form onSubmit={saveNews}>
        <label>{t('admin.news.attributes.title')}</label>
        <br />
        <input
          value={input.title}
          className="px-2 py-1 border rounded-md w-full"
          onChange={(e) => { setInput((prevInput: any) => ({ ...prevInput, title: e.target.value })) }}
        />
        <div className="text-sm text-red-500">
          {map(validationErrors.title, (error: string): string => `${t('admin.news.attributes.title')} ${error}`).join(', ')}
        </div>
        <br />
        <label>{t('admin.news.attributes.pinned_until')}</label>
        <br />
        <input
          value={input.pinned_until}
          type="date"
          className="px-2 py-1 border rounded-md w-full"
          onChange={(e) => { handleDateChange(e, 'pinned_until') }}
        />
        <div className="text-sm text-red-500">
          {map(validationErrors.pinned_until, (error: string): string => `${t('admin.news.attributes.pinned_until')} ${error}`).join(', ')}
        </div>
        <br />
        <label>{t('admin.news.attributes.expires_at')}</label>
        <br />
        <input
          value={input.expires_at}
          type="date"
          className="px-2 py-1 border rounded-md w-full"
          onChange={(e) => { handleDateChange(e, 'expires_at') }}
        />
        <div className="text-sm text-red-500">
          {map(validationErrors.expires_at, (error: string): string => `${t('admin.news.attributes.expires_at')} ${error}`).join(', ')}
        </div>
        <br />
        <label>{t('admin.news.attributes.roles')}</label>
        <br />
        <MultiSelect
          id="roles-select"
          className="w-full shadow-sm rounded-lg"
          options={map(roles, (role: RoleData) => ({
            value: role?.name,
            text: t(`navbar.roles.${role?.name}`)
          }))}
          defaultSelected={map(news?.roles as RoleData[], (role: RoleData) => role.name)}
          placeholder={t('admin.news.placeholders.roles')}
          onChange={(selectedValues: string) => {
            const selectedRoles = selectedValues.split(',')
            setInput((prevInput: any) => ({
              ...prevInput,
              roles: selectedRoles
            }))
          }}
        />
        <div className="text-sm text-red-500">
          {map(validationErrors.roles, (error: string): string => `${t('admin.news.attributes.roles')} ${error}`).join(', ')}
        </div>
        <br />

        <div className="flex items-center space-x-4">
          <label className="text-lg font-medium text-gray-700 w-2/5">{t('admin.news.attributes.required_to_read')}</label>

          <div className="flex items-center space-x-4">
            <label className="flex items-center px-4 cursor-pointer">
              <input
                type="radio"
                value="true"
                name="requiredBooleanChoice"
                checked={input.required_to_read}
                className="form-radio cursor-pointer h-4 w-4 border-gray-700"
                onChange={(e) => { setInput((prevInput: any) => ({ ...prevInput, required_to_read: true })) }}
              />
              <span className="ml-2">{t('common.yes')}</span>
            </label>

            <label className="flex items-center px-4 cursor-pointer">
              <input
                type="radio"
                value="false"
                name="requiredBooleanChoice"
                checked={!input.required_to_read}
                className="form-radio cursor-pointer h-4 w-4 border-gray-700"
                onChange={(e) => { setInput((prevInput: any) => ({ ...prevInput, required_to_read: false })) }}
              />
              <span className="ml-2">{t('common.no')}</span>
            </label>
          </div>
        </div>
        <br />

        <RichTextEditor
          setContent={setInput}
          content={input.content}
          fileUploadURL={API_ADMIN_NEWS_UPLOADS_PATH}
          validationErrors={validationErrors}
        />
        <div className="flex justify-center pt-3">
          <button type="submit" disabled={isEmpty(input)} className="bg-blue-400 rounded-md text-white p-2">
            {t('admin.news.submit_button')}
          </button>
        </div>
      </form>
    </>
  )
}

export default NewsForm
