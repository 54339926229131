import React from 'react'
import type { PopoverProps, CalendarEventPopoverContentProps } from '../../../constants/types'
import { useTranslation } from 'react-i18next'
import { useParseDate } from '../../../hooks/useParseDate'

const CalendarEventPopover: React.FC<PopoverProps> = ({
  data,
  position,
  onClose
}) => {
  if (!position) {
    return (
      <>
        <div
          className="fixed inset-0 z-40"
          onClick={onClose}
        />
        <div className="fixed inset-x-4 bottom-0 -translate-y-1/2 z-50">
          <div
            className="bg-white shadow-lg w-full max-w-sm mx-auto rounded-xl"
            onClick={(e) => { e.stopPropagation() }}
          >
            <PopoverContent
              event={data}
              onClose={onClose}
              isMobile={true}
            />
          </div>
        </div>
      </>
    )
  }

  return (
    <div
      className="absolute bg-white rounded-xl shadow-lg w-80 border border-gray-200"
      style={{
        top: position.top,
        left: position.left,
        zIndex: 999,
        position: 'absolute'
      }}
    >
      <PopoverContent
        event={data}
        onClose={onClose}
        isMobile={false}
      />
    </div>
  )
}

const PopoverContent: React.FC<CalendarEventPopoverContentProps> = ({
  event,
  onClose,
  isMobile
}) => {
  const { t } = useTranslation('translations')
  const { toLocalHour } = useParseDate()

  return (
    <>
      <button
        onClick={onClose}
        className={`absolute ${isMobile ? 'top-3 right-3' : 'top-2 right-2'} text-gray-500 hover:text-gray-700`}
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
        </svg>
      </button>
      <div>
        <h3 className="font-semibold text-md border-l-stone-500 border-l-[3px] border-b rounded-t-xl pl-2 p-4">
          {event.title}
        </h3>
        <div className="px-4 py-4 space-y-6">
          <div className="grid grid-cols-[80px_1fr] gap-x-3">
            <div className="text-xs text-gray-500 self-start">
              {t('calendar.hour')}
            </div>
            <div className="text-sm">
              {toLocalHour(new Date(event.starts_at))} - {toLocalHour(new Date(event.ends_at))}
            </div>
          </div>

          {event.description && (
            <div className="grid grid-cols-[80px_1fr] gap-x-3">
              <div className="text-xs text-gray-500 self-start">
                {t('calendar.calendar_events.description')}
              </div>
              <div className="text-sm line-clamp-3">
                {event.description}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default CalendarEventPopover
