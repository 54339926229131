import React, { type ReactElement } from 'react'
import { Scheduler } from '@aldabil/react-scheduler'

const ParentDashboardView = (): ReactElement => {
  return (
    <div className="p-10">
      <Scheduler view="week" />
    </div>
  )
}

export default ParentDashboardView
