import React, { type FormEvent, type ReactElement } from 'react'
import { map } from 'underscore'
import { useTranslation } from 'react-i18next'
import { useApiPost } from '../../../hooks/api/useApiPost'
import { ADMIN_LESSON_SCHEDULE_IMPORTS_PATH } from '../../../constants/adminRoutes'
import AttachmentsFileInput from '../../common/inputs/AttachmentsFileInput'

const LessonScheduleImportForm = ({ url }: { url: string }
): ReactElement => {
  const { t } = useTranslation('translations')
  const { setInput, sendDataWithFiles, validationErrors, input } = useApiPost({ url, redirect: ADMIN_LESSON_SCHEDULE_IMPORTS_PATH, successFlashMessage: 'Pomyślnie zapisano' })

  const handleSubmit = async (e: FormEvent): Promise<void> => {
    e.preventDefault()
    await sendDataWithFiles({ fileKeys: ['import_file'] })
  }

  return (
    <form method="POST" onSubmit={handleSubmit} className="w-full">
      <br />
      <AttachmentsFileInput defaultInput={input.import_file} setInput={setInput} inputAddKey="import_file" inputRemoveKey="??" multiple={false} />
      <div className="text-sm text-red-500">
        {map(validationErrors.import_file, (error: string): string => `${t('admin.lesson_schedule_imports.attributes.import_file')} ${error}`).join(', ')}
      </div>
      <br />

      <div className="grid place-items-end">
        <button type="submit" className='bg-blue-500 hover:bg-blue-700 text-white py-3 px-6 rounded md:mt-10'>
          {t('admin.lesson_schedule_imports.submit_button')}
        </button>
      </div>
    </form>
  )
}

export default LessonScheduleImportForm
