import { useState, useCallback } from 'react'
import type { CalendarViewState, UseCalendarNavigationReturn } from '../../../constants/types'
import { getWeekBoundaries } from '../utils'

export const useCalendarNavigation = (
  fetchLessonInstances: (params: { starts_at_from: string, starts_at_to: string }) => void,
  calendarRef: React.RefObject<HTMLDivElement>,
  isMobile: boolean
): UseCalendarNavigationReturn => {
  const [weekBoundaries, setWeekBoundaries] = useState(() => getWeekBoundaries(new Date()))
  const [viewState, setViewState] = useState<CalendarViewState>({
    view: 'week',
    currentDate: new Date(),
    selectedDate: new Date()
  })

  const handleDateChange = useCallback((newDate: Date): void => {
    const newBoundaries = getWeekBoundaries(newDate)

    setWeekBoundaries(newBoundaries)
    setViewState(prev => ({ ...prev, currentDate: newDate }))

    fetchLessonInstances({
      starts_at_from: newBoundaries.weekStart.toISOString(),
      starts_at_to: newBoundaries.weekEnd.toISOString()
    })
  }, [fetchLessonInstances])

  const handlePrev = useCallback((): void => {
    if (viewState.view === 'day') {
      const newDate = new Date(viewState.currentDate)
      newDate.setDate(newDate.getDate() - 1)
      handleDateChange(newDate)
    } else {
      const newDate = new Date(weekBoundaries.weekStart)
      newDate.setDate(newDate.getDate() - 7)
      handleDateChange(newDate)
    }
  }, [viewState.view, viewState.currentDate, weekBoundaries.weekStart, handleDateChange])

  const handleNext = useCallback((): void => {
    if (viewState.view === 'day') {
      const newDate = new Date(viewState.currentDate)
      newDate.setDate(newDate.getDate() + 1)
      handleDateChange(newDate)
    } else {
      const newDate = new Date(weekBoundaries.weekStart)
      newDate.setDate(newDate.getDate() + 7)
      handleDateChange(newDate)
    }
  }, [viewState.view, viewState.currentDate, weekBoundaries.weekStart, handleDateChange])

  const handleToday = useCallback((): void => {
    handleDateChange(new Date())

    if (isMobile) {
      setTimeout(() => {
        const calendarContainer = calendarRef.current
        if (calendarContainer) {
          const todayColumnIndex = new Date().getDay() || 7
          const timeColWidth = 60
          const dayColumnWidth = (calendarContainer.scrollWidth - timeColWidth) / 7
          const scrollPosition = timeColWidth + (todayColumnIndex - 1) * dayColumnWidth - 74

          calendarContainer.scrollTo({
            left: scrollPosition,
            behavior: 'smooth'
          })
        }
      }, 100)
    }
  }, [handleDateChange, isMobile, calendarRef])

  return {
    weekBoundaries,
    viewState,
    setViewState,
    handleDateChange,
    handlePrev,
    handleNext,
    handleToday
  }
}
