import React, { type ReactElement, useEffect } from 'react'
import { map } from 'underscore'
import { ADMIN_SUBJECTS_PATH } from '../../../constants/adminRoutes'
import { API_ADMIN_SUBJECT_COLORS_PATH } from '../../../constants/apiRoutes'
import { useTranslation } from 'react-i18next'
import { type SubjectData } from '../../../constants/types'
import { useApiFetch } from '../../../hooks/api/useApiFetch'
import { useApiPost } from '../../../hooks/api/useApiPost'
import Select from '../../common/Select'

const SubjectForm = ({ url, subject, method = 'post' }: { url: string, subject?: SubjectData, method?: string }): ReactElement => {
  const { setInput, sendData, validationErrors, input } = useApiPost({ url, redirect: ADMIN_SUBJECTS_PATH, successFlashMessage: 'Pomyślnie zapisano' })
  const { t } = useTranslation('translations')

  const { data: colors } = useApiFetch({ url: API_ADMIN_SUBJECT_COLORS_PATH, paginated: true })

  useEffect(() => {
    if (subject) {
      setInput((_p: any) => {
        return {
          name: subject.name,
          color: subject.color
        }
      })
    }
  }, [subject])

  const saveSubject = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault()
    await sendData({ method })
  }

  return (
    <>
      <form onSubmit={saveSubject}>
        <br />
        <label>{t('admin.subjects.attributes.name')}</label>
        <br />
        <input
          value={input.name}
          className="px-2 py-1 border rounded-md w-full"
          onChange={(e) => { setInput((prevInput) => ({ ...prevInput, name: e.target.value })) }}
        />
        <div className="text-sm text-red-500">
          {map(validationErrors.name, (error: string): string => `${t('admin.subjects.attributes.name')} ${error}`).join(', ')}
        </div>

        <br />
        <label>{t('admin.subjects.attributes.color')}</label>
        <br />
        <Select
          id="colors-input"
          value={input.color}
          onChange={(color) => { setInput((prevInput: any) => ({ ...prevInput, color })) }}
          placeholder={t('admin.subjects.color_placeholder')}
          options={map(colors, color => ({ value: color, text: color }))}
          optionIcon={(color) => (<div className={`h-5 w-5 bg-${color}`} />) }
        />
        <div className="text-sm text-red-500">
          {map(validationErrors.color, (error: string): string => `${t('admin.subjects.attributes.color')} ${error}`).join(', ')}
        </div>
        <br />

        <div className="flex justify-center pt-3">
          <button type="submit" className="bg-blue-400 rounded-md text-white p-2">
            {t('admin.subjects.submit_button')}
          </button>
        </div>
      </form>
    </>
  )
}

export default SubjectForm
