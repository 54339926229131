import React, { type ReactElement, useState, useEffect, useContext } from 'react'
import { map } from 'underscore'
import { type LessonData, type UseApiGetReturnParams } from '../../constants/types'
import { useTranslation } from 'react-i18next'
import LessonListItem from './LessonListItem'
import Pagination from '../common/Pagination'
import { CurrentUserContext } from '../../contexts/users/CurrentUserContext'
import { TEACHER_ROLE, STUDENT_ROLE } from '../../constants/roles'
import HSSelect from '@preline/select'

const LessonsList = (
  { lessons, paginationMeta, fetchData }:
  { lessons: LessonData[], paginationMeta: Record<string, any>, fetchData: UseApiGetReturnParams['fetchData'] }
): ReactElement => {
  const { t } = useTranslation('translations')
  const { currentUser } = useContext(CurrentUserContext)
  const [sortParams, setSortParams] = useState<{ sort_by: string, sort_order: string }>({ sort_by: '', sort_order: '' })

  useEffect(() => {
    fetchData({ ...sortParams })
    HSSelect.autoInit()
  }, [sortParams])

  const handleSort = (field: string, order: string): void => {
    setSortParams({ sort_by: field, sort_order: order })
  }

  return (
    <div className="w-full flex items-center justify-center">
      <div className="w-full lg:pb-10 lg:pt-5 lg:px-14 pt-5">
        <div className="flex justify-between pb-8 pt-4 lg:block hidden">
          <div className="text-neutral-800 text-[32px] font-semibold font-['DM Sans'] leading-[48px] tracking-tight">
            {t('lessons.h1')}
          </div>
        </div>

        <div className="lg:hidden block mb-5 px-5">
          <div className="relative">
            <select data-hs-select='{
                "placeholder": "Sortuj według...",
                "toggleTag": "<button type=\"button\" aria-expanded=\"false\"></button>",
                "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-3 ps-4 pe-9 flex gap-x-2 text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:outline-none focus:ring-2 focus:ring-blue-500",
                "dropdownClasses": "mt-2 z-50 w-full max-h-72 p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto shadow-xl",
                "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100",
                "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"shrink-0 size-3.5 text-blue-600 \" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" strokeWidth=\"2\" strokeLinecap=\"round\" strokeLinejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
            }'
            onChange={(e) => {
              const [field, order] = e.target.value.split('-')
              handleSort(field, order)
            }}
            defaultValue=""
            >
              <option value="" disabled>{t('Sortuj według...')}</option>
              <option value="subject_name-asc">{t('Przedmiot A-Z')}</option>
              <option value="subject_name-desc">{t('Przedmiot Z-A')}</option>
              {currentUser?.role === STUDENT_ROLE && (
                <>
                  <option value="teacher_name-asc">{t('Prowadzący A-Z')}</option>
                  <option value="teacher_name-desc">{t('Prowadzący Z-A')}</option>
                </>
              )}
            </select>

            <div className="absolute top-1/2 end-2.5 -translate-y-1/2">
              <svg className="shrink-0 h-4 w-4 text-gray-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="m7 15 5 5 5-5"></path>
                <path d="m7 9 5-5 5 5"></path>
              </svg>
            </div>
          </div>
        </div>

        <div className="lg:rounded-md lg:shadow-md lg:border border-gray-200 lg:mb-0 mb-36">
          <div className="card grid grid-cols-12 lg:border lg:border-gray-100 border-b-gray-300 lg:px-5 lg:py-3 bg-gray-100 bg-gray-100 text-sm text-gray-500 rounded-t-md">
            <div className="col-span-2 lg:block hidden">
              <div className="flex space-x-2 hover:cursor-pointer" onClick={() => { handleSort('subject_name', sortParams.sort_order === 'asc' ? 'desc' : 'asc') }}>
                <div className="font-['DM Sans']">
                  {t('lessons.heading.subject')}
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-5 w-5">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
                </svg>
              </div>
            </div>
            {currentUser?.role === TEACHER_ROLE && (
              <div className="col-span-4 lg:block hidden">
                <div className="font-['DM Sans']">
                  {t('lessons.heading.class_units_caps')}
                </div>
              </div>
            )}
            {currentUser?.role === STUDENT_ROLE && (
              <div className="col-span-4 lg:block hidden">
                <div className="flex space-x-2 hover:cursor-pointer" onClick={() => { handleSort('teacher_name', sortParams.sort_order === 'asc' ? 'desc' : 'asc') }}>
                  <div className="font-['DM Sans']">
                    {t('lessons.heading.teacher_caps')}
                  </div>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-5 w-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
                  </svg>
                </div>
              </div>
            )}
          </div>
          <div className="lg:divide-y">
            {
              map(lessons, (lesson: LessonData): ReactElement => (
                <LessonListItem lesson={lesson} key={lesson.id} />
              ))
            }
          </div>
          <div className="p-4 rounded-b-lg border border-transparent border-t-gray-200">
            <Pagination paginationMeta={paginationMeta} fetchData={fetchData} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default LessonsList
