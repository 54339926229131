import type { CalendarEventData, LessonInstanceData, LessonSlotItemData } from '../../../constants/types'

export const getWeekBoundaries = (date: Date): { weekStart: Date, weekEnd: Date } => {
  const currentDate = new Date(date)
  const dayOfWeek = currentDate.getDay()

  const weekStart = new Date(currentDate)
  weekStart.setDate(currentDate.getDate() - ((dayOfWeek + 6) % 7))
  weekStart.setHours(0, 0, 0, 0)

  const weekEnd = new Date(weekStart)
  weekEnd.setDate(weekStart.getDate() + 6)
  weekEnd.setHours(23, 59, 59, 999)

  return { weekStart, weekEnd }
}

export const isLessonInstance = (event: any): boolean => {
  return 'subject' in event && 'starts_at' in event && 'ends_at' in event
}

export const isEventDuringSlot = (
  event: CalendarEventData,
  slot: Pick<LessonSlotItemData, 'starts_at' | 'ends_at'>,
  date: Date
): boolean => {
  if ('deadline' in event) {
    return false
  }
  const eventStart = new Date(event.starts_at)
  const eventEnd = new Date(event.ends_at)

  const [slotStartHours, slotStartMinutes] = slot.starts_at.split(':').map(Number)
  const [slotEndHours, slotEndMinutes] = slot.ends_at.split(':').map(Number)

  const slotStart = new Date(date)
  const slotEnd = new Date(date)

  slotStart.setHours(slotStartHours, slotStartMinutes, 0, 0)
  slotEnd.setHours(slotEndHours, slotEndMinutes, 0, 0)

  return eventStart <= slotEnd && eventEnd >= slotStart
}

export const parseTimeSlot = (slot: string): { starts_at: string, ends_at: string } => {
  const [startsAt, endsAt] = slot.split('-')
  return {
    starts_at: startsAt,
    ends_at: endsAt
  }
}

export const getEventTimeSpans = (
  events: CalendarEventData[],
  currentSlot: string,
  timeSlots: string[]
): Array<{ event: CalendarEventData, spanRows: number, isStart: boolean }> => {
  const currentSlotIndex = timeSlots.indexOf(currentSlot)

  return events.map(event => {
    const eventStart = new Date(event.starts_at)
    let spanRows = 1

    const isStart = currentSlotIndex === 0 || !isEventDuringSlot(
      event,
      parseTimeSlot(timeSlots[currentSlotIndex - 1]),
      new Date(eventStart)
    )

    for (let i = currentSlotIndex + 1; i < timeSlots.length; i++) {
      const nextSlot = parseTimeSlot(timeSlots[i])
      if (isEventDuringSlot(event, nextSlot, new Date(eventStart))) {
        spanRows++
      } else {
        break
      }
    }

    return { event, spanRows, isStart }
  })
}

export const getDayEvents = (
  date: Date,
  lessonInstances: LessonInstanceData[] = [],
  calendarEvents: CalendarEventData[] = []
): Array<LessonInstanceData | CalendarEventData> => {
  const dateString = date.toDateString()

  return [
    ...lessonInstances.filter(lesson =>
      new Date(lesson.starts_at).toDateString() === dateString
    ),
    ...calendarEvents.filter(event =>
      new Date(event.starts_at).toDateString() === dateString
    )
  ]
}

export const shouldBeHalfWidth = (
  currentSlotIndex: number,
  spanCount: number,
  dayEvents: Array<CalendarEventData | LessonInstanceData>,
  timeSlots: string[],
  date: Date
): boolean => {
  for (let i = currentSlotIndex; i < currentSlotIndex + spanCount; i++) {
    const [startTime, endTime] = timeSlots[i].split('-')
    const hasLessonInThisSlot = dayEvents.some(dayEvent =>
      isLessonInstance(dayEvent) &&
      isEventDuringSlot(dayEvent, { starts_at: startTime, ends_at: endTime }, date)
    )
    if (hasLessonInThisSlot) {
      return true
    }
  }
  return false
}

export const calculateTotalHeight = (
  currentSlotIndex: number,
  spanCount: number
): string => {
  let totalHeight = 0
  for (let i = currentSlotIndex; i < currentSlotIndex + spanCount; i++) {
    const slotElement = document.querySelector(`[data-slot-index="${i}"]`)
    if (slotElement) {
      // Look specifically for lesson buttons and their container height
      const lessonsContainer = Array.from(slotElement.querySelectorAll('.w-full.relative'))
        .filter(el => el.children[0]?.classList.contains('border-l-blue-500'))
      const lessonsHeight = lessonsContainer.reduce((sum, el) => sum + el.getBoundingClientRect().height, 0)

      // Use base height if there are no lessons
      const baseHeight = slotElement.getBoundingClientRect().height - 4 // subtract padding
      totalHeight += lessonsHeight || baseHeight
    }
  }
  return `${totalHeight + (spanCount > 1 ? 4 : 0)}px`
}

export const getTimeSlots = (lessonSlots: LessonSlotItemData[]): string[] => {
  if (!lessonSlots || !Array.isArray(lessonSlots)) return []

  const timeSlotsArray = lessonSlots.map((slot: LessonSlotItemData) => {
    const timeRange = `${slot.starts_at}-${slot.ends_at}`
    return timeRange
  })

  const uniqueTimeSlots = Array.from(new Set(timeSlotsArray))
  return uniqueTimeSlots.sort((a, b) => {
    const [startA] = a.split('-')
    const [startB] = b.split('-')
    return startA.localeCompare(startB)
  })
}

export const isToday = (date: Date): boolean => {
  const today = new Date()
  return date.toDateString() === today.toDateString()
}
