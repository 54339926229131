import { useState, useContext } from 'react'
import { FlashContext } from '../../contexts/flash/FlashContext'
import { CurrentUserContext } from '../../contexts/users/CurrentUserContext'
import { FlashType, type ValidationError, type UseApiPostReturnParams } from '../../constants/types'
import { useNavigate } from 'react-router-dom'
import GetRootFromRoleService from '../../services/users/GetRootFromRoleService'
import { useHandleApiError } from './useHandleApiError'
import axios from '../../config/axios'
import { each, isEmpty, isObject, isArray, omit } from 'underscore'

export const useApiPost = (
  {
    url, successFlashMessage = '', failureFlashMessage = 'Coś poszło nie tak', redirect = ''
  }: { url?: string, successFlashMessage?: string, failureFlashMessage?: string, redirect?: string }
): UseApiPostReturnParams => {
  const { setFlash } = useContext(FlashContext)
  const { currentUser } = useContext(CurrentUserContext)
  const handleError = useHandleApiError(failureFlashMessage)
  const [validationErrors, setValidationErrors] = useState({} as ValidationError['details'])
  const [input, setInput] = useState({} as Record<string, any>)
  const navigate = useNavigate()

  const redirectPath = (): string => {
    if (redirect === 'root') return GetRootFromRoleService.call(currentUser)
    return redirect
  }

  const appendToFormData = (
    formData: FormData,
    data: any,
    parentKey: string = ''
  ): void => {
    if (data && isObject(data) && !(data instanceof File) && !(data instanceof Blob)) {
      each(data, (value, key) => {
        const fullKey = parentKey ? `${parentKey}[${key}]` : key

        if (value !== null && value !== undefined) {
          if (value instanceof File || value instanceof Blob) {
            formData.append(fullKey, value)
          } else if (isArray(value)) {
            if (isEmpty(value)) {
              formData.append(`${fullKey}[]`, '')
            } else {
              each(value, (item, index) => {
                if (item instanceof File || item instanceof Blob) {
                  formData.append(`${fullKey}[]`, item)
                } else {
                  appendToFormData(formData, item, `${fullKey}[${index}]`)
                }
              })
            }
          } else if (isObject(value)) {
            appendToFormData(formData, value, fullKey)
          } else {
            formData.append(fullKey, value.toString())
          }
        }
      })
    } else if (data instanceof File || data instanceof Blob) {
      formData.append(parentKey, data)
    } else if (data !== null && data !== undefined) {
      formData.append(parentKey, data.toString())
    }
  }

  const sendData = async ({
    callback = async (_response: any) => null,
    additionalParams = {},
    headers = {},
    method = 'post',
    apiUrl = url
  } = {}): Promise<any> => {
    try {
      const response = await (axios as any)[method](apiUrl, { ...input, ...additionalParams }, { headers })
      await callback(response)

      if (successFlashMessage) setFlash({ message: successFlashMessage, type: FlashType.success })

      if (redirect) {
        navigate(redirectPath())
      } else {
        return response.data.data
      }
    } catch (error: any) {
      if (error.response.status === 422) {
        setValidationErrors(error.response.data.details)
        return
      }
      handleError(error)
    }
  }

  const sendDataWithFiles = async ({
    fileKeys,
    callback = async (_response: any) => null,
    additionalParams = {},
    headers = {},
    method = 'post'
  }: {
    fileKeys: string[]
    callback?: (response: any) => Promise<any>
    additionalParams?: Record<string, any>
    headers?: Record<string, string>
    method?: string
  }): Promise<any> => {
    const formData = new FormData()
    const dataToSend = { ...input, ...additionalParams }
    const processedFileKeys: string[] = []

    each(fileKeys, (fileKey) => {
      if (dataToSend[fileKey]) {
        if (dataToSend[fileKey] instanceof File) {
          formData.append(fileKey, dataToSend[fileKey])
        } else if (isArray(dataToSend[fileKey])) {
          each(dataToSend[fileKey].filter((file: any) => !file.id), (file: File) => {
            formData.append(`${fileKey}[]`, file)
          })
        }
        processedFileKeys.push(fileKey)
      }
    })

    appendToFormData(formData, omit(dataToSend, processedFileKeys))

    try {
      const response = await (axios as any)[method](url, formData, {
        headers: { ...headers, 'Content-Type': 'multipart/form-data' }
      })

      await callback(response)

      if (successFlashMessage) {
        setFlash({ message: successFlashMessage, type: FlashType.success })
      }

      if (redirect) {
        navigate(redirectPath())
      } else {
        return response.data.data
      }
    } catch (error: any) {
      if (error.response.status === 422) {
        setValidationErrors(error.response.data.details)
        return
      }
      handleError(error)
    }
  }

  return { sendData, sendDataWithFiles, setInput, validationErrors, input }
}
