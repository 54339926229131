import React, { type ReactElement, useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { LessonViewContext } from '../../contexts/common/LessonViewContext'
import { Link } from 'react-router-dom'
import DefaultAvatar from '../../assets/default_avatar.svg'
import { TEACHER_ROLE, STUDENT_ROLE } from '../../constants/roles'
import { TEACHER_LESSON_HOMEWORKS_PATH } from '../../constants/teacherRoutes'
import { USER_PROFILE_PATH } from '../../constants/routes'
import { CurrentUserContext } from '../../contexts/users/CurrentUserContext'
import TopicsAccordion from './TopicsAccordion'
import ExamsAccordion from './ExamsAccordion'
import HomeworksAccordion from './HomeworksAccordion'
import FilesAccordion from './FilesAccordion'
import { useParseDate } from '../../hooks/useParseDate'
import GradeIcon from '../../assets/icons/grade.svg'
import AttendanceIcon from '../../assets/icons/calendar-days.svg'
import HSAccordion from '@preline/accordion'

const LessonView = ({ lesson }: { lesson: Record<string, any> }): ReactElement => {
  const { t } = useTranslation('translations')
  const { setLessonSubjectAndUnits } = useContext(LessonViewContext)
  const { currentUser } = useContext(CurrentUserContext)
  const { toLocalDateWithHour } = useParseDate()
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1024)

  useEffect(() => {
    HSAccordion.autoInit()
    const updateScreenSize = (): void => {
      setIsLargeScreen(window.innerWidth >= 1024)
    }
    window.addEventListener('resize', updateScreenSize)
    return () => {
      window.removeEventListener('resize', updateScreenSize)
    }
  })

  const header = (): string => {
    const classUnitNames = lesson?.class_units && lesson?.class_units?.length > 0
      ? lesson?.class_units.map((unit: { name: string }) => unit.name).join('_')
      : null

    const header = classUnitNames ? `${lesson?.subject?.name} - ${classUnitNames}` : lesson?.subject?.name
    setLessonSubjectAndUnits(header)
    return header
  }

  const PresenceIcon = (): ReactElement => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="green" className="w-6 h-6">
      <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
    </svg>
  )

  const AbsenceIcon = (): ReactElement => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="red" className="h-6 w-6">
      <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
    </svg>

  )

  const JustifiedAbsenceIcon = (): ReactElement => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#e5a60d" className="w-6 h-6">
      <path strokeLinecap="round" strokeLinejoin="round" d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 0 1 9 9v.375M10.125 2.25A3.375 3.375 0 0 1 13.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 0 1 3.375 3.375M9 15l2.25 2.25L15 12" />
    </svg>
  )

  const SchoolPresenceIcon = (): ReactElement => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="green" className="h-6 w-6">
      <path strokeLinecap="round" strokeLinejoin="round" d="M4.26 10.147a60.438 60.438 0 0 0-.491 6.347A48.62 48.62 0 0 1 12 20.904a48.62 48.62 0 0 1 8.232-4.41 60.46 60.46 0 0 0-.491-6.347m-15.482 0a50.636 50.636 0 0 0-2.658-.813A59.906 59.906 0 0 1 12 3.493a59.903 59.903 0 0 1 10.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.717 50.717 0 0 1 12 13.489a50.702 50.702 0 0 1 7.74-3.342M6.75 15a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm0 0v-3.675A55.378 55.378 0 0 1 12 8.443m-7.007 11.55A5.981 5.981 0 0 0 6.75 15.75v-1.5" />
    </svg>
  )

  const LateIcon = (): ReactElement => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#e5a60d" className="w-6 h-6">
      <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
    </svg>
  )

  const attendanceTypeIcons: Record<string, ReactElement> = {
    presence: <PresenceIcon />,
    absence: <AbsenceIcon />,
    justified_absence: <JustifiedAbsenceIcon />,
    school_presence: <SchoolPresenceIcon />,
    late: <LateIcon />
  }

  const TeachersList = ({ lesson, t }: { lesson: Record<string, any>, t: (key: string) => string }): ReactElement => {
    return (
      <div className="flex flex-row space-x-4 lg:mx-4">
        <div className="flex flex-col items-end w-24">
          <div className="font-['DM Sans'] text-zinc-700">
            {t('lessons.heading.teacher')}:
          </div>
        </div>
        <div className="flex flex-col items-start flex-1">
          <div className="flex flex-col gap-2">
            {lesson?.teachers?.map((teacher) => (
              <Link
                key={teacher.id}
                to={USER_PROFILE_PATH.replace(':id', teacher.user?.id.toString())}
                className="flex gap-3 lg:items-center items-start font-['DM Sans']"
              >
                <img
                  src={teacher.user?.avatar.url ?? DefaultAvatar}
                  className="object-cover cursor-pointer h-7 w-7 rounded-full flex-shrink-0 mt-0.5"
                  alt="Avatar"
                />
                <span className="lg:pt-0 pt-1">{teacher.user?.name}</span>
              </Link>
            ))}
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="flex justify-between items-center">
        <div className="text-left text-[32px] font-bold lg:block hidden">
          {header()}
        </div>
        {currentUser?.role === TEACHER_ROLE &&
          <div className="flex space-x-2 hidden lg:block">
            <Link
              to=""
              className="h-10 pl-3 pr-4 py-2 bg-white hover:bg-gray-200 hover:cursor-pointer rounded-lg border border-blue-800 justify-start items-center gap-2 inline-flex"
            >
              <div className="text-blue-900 text-sm font-medium font-['DM Sans']">
                {t('lesson.edit_lesson')}
              </div>
              <div className="w-6 h-6 relative">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="text-blue-800">
                  <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                </svg>
              </div>
            </Link>
            <Link
              to=""
              className="h-10 pl-3 pr-4 py-2 bg-white hover:bg-gray-200 hover:cursor-pointer rounded-lg border border-blue-800 justify-start items-center gap-2 inline-flex"
            >
              <div className="text-blue-900 text-sm font-medium font-['DM Sans']">
                {t('lesson.settings')}
              </div>
              <div className="w-6 h-6 relative">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="text-blue-800">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z" />
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                </svg>
              </div>
            </Link>
          </div>
        }
      </div>
      <div className="lg:py-[30px] py-[20px]">
        <TeachersList lesson={lesson} t={t} />
        <div className="flex flex-row space-x-4 lg:mx-4 mt-3">
          <div className="flex flex-col items-end w-24">
            <div className="font-['DM Sans'] text-zinc-700">
              {t('lessons.heading.class_units')}:
            </div>
          </div>
          <div className="flex flex-col items-start flex-1">
            {lesson?.class_units && lesson.class_units.length > 0 && (
              <div className="flex gap-3 font-['DM Sans']">
                {lesson.class_units.map((unit) => (
                  <Link
                    key={unit.name}
                    to=""
                    className={`text-${unit.color} bg-${unit.color} bg-opacity-20 py-0.5 px-3 rounded-xl`}
                  >
                    {unit.name}
                  </Link>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      {currentUser?.role === TEACHER_ROLE &&
        <>
          <div className="flex space-x-2 block lg:hidden pb-2">
            <Link
              to=""
              className="h-10 pl-3 pr-4 py-2 bg-white hover:bg-gray-200 hover:cursor-pointer rounded-lg border border-blue-800 justify-start items-center gap-2 inline-flex"
            >
              <div className="text-blue-900 text-sm font-medium font-['DM Sans']">
                {t('lesson.edit_lesson')}
              </div>
              <div className="w-6 h-6 relative">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="text-blue-800">
                  <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                </svg>
              </div>
            </Link>
            <Link
              to=""
              className="h-10 pl-3 pr-4 py-2 bg-white hover:bg-gray-200 hover:cursor-pointer rounded-lg border border-blue-800 justify-start items-center gap-2 inline-flex"
            >
              <div className="text-blue-900 text-sm font-medium font-['DM Sans']">
                {t('lesson.settings')}
              </div>
              <div className="w-6 h-6 relative">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="text-blue-800">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z" />
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                </svg>
              </div>
            </Link>
          </div>
          <div className="flex space-x-2 border-b border-gray-300 pb-1 overflow-x-auto">
            <Link
              to=""
              className="px-4 py-2 w-max hover:text-zinc-500 text-zinc-700 whitespace-nowrap"
            >
              {t('lessons.links.topics')}
            </Link>
            <Link
              to=""
              className="px-4 py-2 w-max hover:text-zinc-500 text-zinc-700 whitespace-nowrap"
            >
              {t('lessons.links.attendances')}
            </Link>
            <Link
              to=""
              className="px-4 py-2 w-max hover:text-zinc-500 text-zinc-700 whitespace-nowrap"
            >
              {t('lessons.links.grades')}
            </Link>
            <Link
              to=""
              className="px-4 py-2 w-max hover:text-zinc-500 text-zinc-700 whitespace-nowrap"
            >
              {t('lessons.links.announcements')}
            </Link>
            <Link
              to={TEACHER_LESSON_HOMEWORKS_PATH.replace(':id', lesson?.id?.toString())}
              className="px-4 py-2 w-max hover:text-zinc-500 text-zinc-700 whitespace-nowrap"
            >
              {t('lessons.links.homeworks')}
            </Link>
            <Link
              to=""
              className="px-4 py-2 w-max hover:text-zinc-500 text-zinc-700 whitespace-nowrap"
            >
              {t('lessons.links.discipline_notes')}
            </Link>
            <Link
              to=""
              className="px-4 py-2 w-max hover:text-zinc-500 text-zinc-700 whitespace-nowrap"
            >
              {t('lessons.links.reviews')}
            </Link>
            <Link
              to=""
              className="px-4 py-2 w-max hover:text-zinc-500 text-zinc-700 whitespace-nowrap"
            >
              {t('lessons.links.conversations_with_parents')}
            </Link>
          </div>
        </>
      }
      <div className="grid lg:grid-cols-3 lg:gap-4 gap-2 mt-4">
        <div className="flex-col justify-start items-start gap-2 inline-flex">
          {currentUser?.role === STUDENT_ROLE && (
            <>
              <ExamsAccordion lesson={lesson} />
              <HomeworksAccordion lesson={lesson} />
            </>
          )}
          {currentUser?.role === TEACHER_ROLE && <TopicsAccordion lesson={lesson} />}
          <div className="w-full hs-accordion-group shadow border border-gray-200 rounded-2xl bg-white">
            <div className={`hs-accordion ${isLargeScreen ? 'active' : ''}`} id="lesson-news-accordion">
              <button className="hs-accordion-toggle py-5 px-4 inline-flex items-center justify-between gap-x-3 w-full font-semibold text-start hover:text-gray-500 rounded-lg disabled:opacity-50 disabled:pointer-events-none" aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-one">
                {t('lessons.links.lesson_news')}
                <svg className="hs-accordion-active:hidden block size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="m6 9 6 6 6-6"></path>
                </svg>
                <svg className="hs-accordion-active:block hidden size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="m18 15-6-6-6 6"></path>
                </svg>
              </button>
              <div id="lesson-news-accordion" className="hs-accordion-content lg:block hidden hs-accordion-active:border-t hs-accordion-active:border-gray-200 w-full px-4 overflow-hidden transition-[height] duration-300" aria-labelledby="lesson-news-accordion">
                <div className="pt-1">
                  <div className="self-stretch flex-col justify-start items-start flex">
                    <div className="self-stretch py-5 justify-end items-center gap-2 inline-flex">
                      <Link
                        to=""
                        className="flex items-center gap-2 text-blue-900 hover:text-blue-500 text-sm font-medium font-['DM Sans'] leading-tight"
                      >
                        {t('lessons.links.all_lesson_news')}
                        <div className="w-5 h-5 relative">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                          </svg>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {currentUser?.role === STUDENT_ROLE && <FilesAccordion lesson={lesson} />}
        </div>

        <div className="flex-col justify-start items-start gap-2 inline-flex">
          {currentUser?.role === TEACHER_ROLE && (
            <>
              <ExamsAccordion lesson={lesson} />
              <HomeworksAccordion lesson={lesson} />
              <FilesAccordion lesson={lesson} />
            </>
          )}
          {currentUser?.role === STUDENT_ROLE &&
            <>
              <div className="w-full hs-accordion-group shadow border border-gray-200 rounded-2xl bg-white">
                <div className={`hs-accordion ${isLargeScreen ? 'active' : ''}`} id="grades-accordion">
                  <button className="hs-accordion-toggle py-5 px-4 inline-flex items-center justify-between gap-x-3 w-full font-semibold text-start hover:text-gray-500 rounded-lg disabled:opacity-50 disabled:pointer-events-none" aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-one">
                    <div className="inline-flex gap-3 items-center">
                      <img src={GradeIcon} alt="Grade" className="w-7 h-7 relative bg-gray-200 p-[3px] text-gray-800" />
                      {t('lessons.links.my_grades')}
                    </div>
                    <svg className="hs-accordion-active:hidden block size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                      <path d="m6 9 6 6 6-6"></path>
                    </svg>
                    <svg className="hs-accordion-active:block hidden size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                      <path d="m18 15-6-6-6 6"></path>
                    </svg>
                  </button>
                  <div id="grades-accordion" className="hs-accordion-content lg:block hidden hs-accordion-active:border-t hs-accordion-active:border-gray-200 w-full px-4 overflow-hidden transition-[height] duration-300" aria-labelledby="grades-accordion">
                    {lesson?.grades && (
                      <div className="py-1">
                        {lesson?.grades?.map((grade: { id: string, value: string, note: string, created_at: Date }) => (
                          <div key={grade.id} className="self-stretch flex-col justify-start items-start flex">
                            <div className="self-stretch py-1 justify-start items-center gap-3 inline-flex">
                              <div className="text-neutral-800 text-sm max-w-[175px] truncate font-semibold font-['DM Sans'] pt-2 leading-normal">
                                {grade.value}
                              </div>
                              <div className="grow shrink basis-0 text-neutral-800 text-sm font-normal font-['DM Sans'] pt-2 leading-normal truncate">
                                {grade.note}
                              </div>
                            </div>
                            <div className="basis-0 text-neutral-500 text-xs font-medium font-['DM Sans'] pb-2 leading-normal">
                              {toLocalDateWithHour(grade.created_at)}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                    <div className="pt-1">
                      <div className="self-stretch flex-col justify-start items-start flex">
                        <div className="self-stretch py-5 justify-end items-center gap-2 inline-flex">
                          <Link
                            to=""
                            className="flex items-center gap-2 text-blue-900 hover:text-blue-500 text-sm font-medium font-['DM Sans'] leading-tight"
                          >
                            {t('lessons.links.all_grades')}
                            <div className="w-5 h-5 relative">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                              </svg>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full hs-accordion-group shadow border border-gray-200 rounded-2xl bg-white">
                <div className={`hs-accordion ${isLargeScreen ? 'active' : ''}`} id="attendances-accordion">
                  <button className="hs-accordion-toggle py-5 px-4 inline-flex items-center justify-between gap-x-3 w-full font-semibold text-start hover:text-gray-500 rounded-lg disabled:opacity-50 disabled:pointer-events-none" aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-one">
                    <div className="inline-flex gap-3 items-center">
                      <img src={AttendanceIcon} alt="Grade" className="w-7 h-7 relative bg-gray-200 p-[3px] text-gray-800" />
                      {t('lessons.links.my_attendances')}
                    </div>
                    <svg className="hs-accordion-active:hidden block size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                      <path d="m6 9 6 6 6-6"></path>
                    </svg>
                    <svg className="hs-accordion-active:block hidden size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                      <path d="m18 15-6-6-6 6"></path>
                    </svg>
                  </button>
                  <div id="attendances-accordion" className="hs-accordion-content lg:block hidden hs-accordion-active:border-t hs-accordion-active:border-gray-200 w-full px-4 overflow-hidden transition-[height] duration-300" aria-labelledby="attendances-accordion">
                    {lesson?.lesson_instances && (
                      <div className="py-1">
                        {lesson?.lesson_instances?.map((lessonInstance: { id: string, topic: string, starts_at: Date, attendance: { attendance_type: string } }) => (
                          <div key={lessonInstance.id} className="self-stretch flex-col justify-start items-start flex">
                            <div className="self-stretch py-1 justify-start items-center gap-3 inline-flex">
                              <div className="text-neutral-500 text-sm font-normal font-['DM Sans'] py-2 leading-normal">
                                {toLocalDateWithHour(lessonInstance.starts_at)}
                              </div>
                              <div className="grow shrink truncate basis-0 text-neutral-800 text-sm font-normal font-['DM Sans'] py-2 leading-normal">
                                {lessonInstance.topic}
                              </div>
                              <div className="basis-0 text-neutral-500 text-sm font-medium font-['DM Sans'] py-2 leading-normal">
                                {attendanceTypeIcons[lessonInstance.attendance.attendance_type]}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                    <div className="pt-1">
                      <div className="self-stretch flex-col justify-start items-start flex">
                        <div className="self-stretch py-5 justify-end items-center gap-2 inline-flex">
                          <Link
                            to=""
                            className="flex items-center gap-2 text-blue-900 hover:text-blue-500 text-sm font-medium font-['DM Sans'] leading-tight"
                          >
                            {t('lessons.links.all_attendances')}
                            <div className="w-5 h-5 relative">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                              </svg>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
        </div>

        <div className="flex-col justify-start items-start gap-2 inline-flex lg:mb-0 mb-32">
          <div className="w-full hs-accordion-group shadow border border-gray-200 rounded-2xl bg-white">
            <div className={`hs-accordion ${isLargeScreen ? 'active' : ''}`} id="students-accordion">
              <button className="hs-accordion-toggle py-[18px] px-4 inline-flex items-center justify-between gap-x-3 w-full font-semibold text-start hover:text-gray-500 rounded-lg disabled:opacity-50 disabled:pointer-events-none" aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-one">
                <div className="inline-flex gap-3 items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-7 h-7 relative bg-gray-200 p-[3px] text-gray-800">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" />
                  </svg>
                  {t('lessons.links.students')}
                </div>
                <svg className="hs-accordion-active:hidden block size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="m6 9 6 6 6-6"></path>
                </svg>
                <svg className="hs-accordion-active:block hidden size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="m18 15-6-6-6 6"></path>
                </svg>
              </button>
              <div id="students-accordion" className={`hs-accordion-content lg:block hidden ${lesson?.students?.length > 0 ? 'hs-accordion-active:border-t' : ''} hs-accordion-active:border-gray-200 w-full px-4 overflow-hidden transition-[height] duration-300" aria-labelledby="students-accordion`}>
                {lesson?.students && (
                  <div className="py-1">
                    {lesson?.students?.map((student: { id: string, class_unit: { id: string, name: string, color: string }, user: { id: string, name: string, avatar: { url: string } } }, index: number) => (
                      <Link key={student.id} className="self-stretch flex-col justify-start items-start flex" to={USER_PROFILE_PATH.replace(':id', student?.user?.id.toString())}>
                        <div className="py-1 justify-start items-center gap-2 inline-flex">
                          <div className="w-fit inline-block text-neutral-800 text-sm font-medium font-['DM Sans'] py-2 leading-normal">
                            <div className="flex gap-3 items-center font-['DM Sans']">
                              {index + 1}.
                              <img
                                src={student?.user?.avatar?.url ?? DefaultAvatar}
                                className="object-cover cursor-pointer h-6 w-6 rounded-full hover:h-10 hover:w-10"
                                alt="Avatar"
                              />
                              {student.user.name}
                            </div>
                          </div>
                          {student.class_unit.name && (
                            <span className={`py-[3px] px-1.5 inline-flex items-center gap-x-1 text-[11px] rounded-lg bg-${student.class_unit.color} bg-opacity-20 text-${student.class_unit.color}`}>
                              {student.class_unit.name}
                            </span>
                          )}
                        </div>
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LessonView
