import React from 'react'
import { useTranslation } from 'react-i18next'
import type { CalendarViewState, NavigationHeaderProps } from '../../../constants/types'
import Select from '../../common/Select'

export const NavigationHeader: React.FC<NavigationHeaderProps> = ({
  weekBoundaries,
  viewState,
  onPrev,
  onNext,
  onToday,
  onViewChange
}) => {
  const { t } = useTranslation('translations')

  const polishMonths = [
    t('calendar.months_genitive.january'),
    t('calendar.months_genitive.february'),
    t('calendar.months_genitive.march'),
    t('calendar.months_genitive.april'),
    t('calendar.months_genitive.may'),
    t('calendar.months_genitive.june'),
    t('calendar.months_genitive.july'),
    t('calendar.months_genitive.august'),
    t('calendar.months_genitive.september'),
    t('calendar.months_genitive.october'),
    t('calendar.months_genitive.november'),
    t('calendar.months_genitive.december')
  ]

  const weekStart = weekBoundaries.weekStart
  const weekEnd = weekBoundaries.weekEnd
  const rangeText = `${weekStart.getDate()}-${weekEnd.getDate()} ${polishMonths[weekStart.getMonth()]} ${weekStart.getFullYear()}`

  const viewOptions = [
    { value: 'day', text: 'Dzień' },
    { value: 'week', text: 'Tydzień' }
  ]

  return (
    <div className="flex flex-col lg:flex-row lg:justify-between items-center">
      <div className="hidden lg:block w-36" />
      <button
        type="button"
        onClick={onToday}
        className="w-full mb-4 py-2 lg:hidden px-2 text-sm border border-blue-500 bg-white text-blue-700 shadow-md rounded-md hover:bg-gray-50"
      >
        {t('calendar.today')}
      </button>
      <div className="flex items-center gap-2">
        <button
          type="button"
          onClick={onPrev}
          className="inline-flex justify-center items-center text-xs rounded-md border border-blue-500 bg-white text-blue-700 shadow-sm hover:bg-gray-50 p-1"
        >
          <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M15 18L9 12L15 6" />
          </svg>
        </button>

        <div className="flex items-center gap-2">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
          </svg>
          <span className="text-sm">{rangeText}</span>
        </div>

        <button
          type="button"
          onClick={onNext}
          className="inline-flex justify-center items-center text-xs rounded-md border border-blue-500 bg-white text-blue-700 shadow-sm hover:bg-gray-50 p-1"
        >
          <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M9 18L15 12L9 6" />
          </svg>
        </button>
      </div>

      <div className="hidden lg:flex items-center gap-4 justify-end">
        <button
          type="button"
          onClick={onToday}
          className="py-1 px-2 lg:inline-flex hidden items-center text-sm border border-blue-500 bg-white text-blue-700 shadow-md rounded-md hover:bg-gray-50"
        >
          {t('calendar.today')}
        </button>
        <Select
          options={viewOptions}
          placeholder="Wybierz widok"
          onChange={(value) => { onViewChange(value as CalendarViewState['view']) }}
          value={viewState.view}
          id="calendar-view-select"
          className="w-[110px] relative"
          toggleClasses="py-[5px] px-2"
          toggleColors="border-blue-500 shadow-none text-blue-700"
        />
      </div>
    </div>
  )
}
