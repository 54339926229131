import React, { type ReactElement } from 'react'
import ProfileForm from '../../components/profiles/ProfileForm'
import { API_USERS_PROFILE_PATH } from '../../constants/apiRoutes'
import { ADMIN_TEACHERS_PATH } from '../../constants/adminRoutes'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useApiFetch } from '../../hooks/api/useApiFetch'

const EditUserProfileView = (): ReactElement => {
  const queryParams = useParams()
  const apiUrl = API_USERS_PROFILE_PATH.replace(':id', queryParams.id as string)

  const { data: currentUserData } = useApiFetch({ url: apiUrl })

  const { t } = useTranslation('translations')
  return (
    <div className="grid w-full flex justify-center" >
      <div className="px-5 lg:px-14 py-8">
        <h1 className="text-2xl lg:text-3xl text-left pb-12">{t('users.profile.edit_header')}</h1>
        <ProfileForm url={apiUrl} profileData={currentUserData} redirect={ADMIN_TEACHERS_PATH} method={'patch'}/>
      </div>
    </div>
  )
}

export default EditUserProfileView
