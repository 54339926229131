import React, { type ReactElement } from 'react'

const Bar = ({ text, bgColor, textColor }: { text: string, bgColor: string, textColor: string }): ReactElement => {
  return (
    <div className={`bg-${bgColor} py-1 px-2 rounded-lg w-max`}>
      <div className={`text-${textColor}`}>
        {text}
      </div>
    </div>
  )
}

export default Bar
