import React, { useContext } from 'react'
import type { LessonInstanceData } from '../../../constants/types'
import { attendanceIcons } from '../icons'
import { CurrentUserContext } from '../../../contexts/users/CurrentUserContext'
import { TEACHER_ROLE } from '../../../constants/roles'

const LessonButton = ({
  event,
  onClick,
  isActive = false
}: {
  event: LessonInstanceData
  onClick: (event: LessonInstanceData, e: React.MouseEvent) => void
  isActive?: boolean
}): JSX.Element => {
  const { currentUser } = useContext(CurrentUserContext)
  const isTeacher = currentUser?.role === TEACHER_ROLE

  return (
    <button
      data-lesson-id={event.id}
      onClick={(e) => { onClick(event, e) }}
      className={`px-2 py-2 text-xs font-extrabold truncate text-left inline-block border ${
        isActive ? 'border-blue-500' : 'border-gray-200'
      } border-l-[3px] border-l-${event.subject.color} bg-white w-full rounded shadow-sm relative`}
    >
      <div className="flex justify-between text-[11px]">
        <div className="truncate font-extrabold">
          {event.lesson.name}
        </div>
        {event.classroom && (
          <div>
            {event.classroom.number}
          </div>
        )}
      </div>
      <div className="flex flex-wrap gap-1 text-gray-600 text-[10px] font-thin pt-1">
        {isTeacher
          ? (
            event.class_units?.map((unit) => (
              <span key={unit.id} className={`whitespace-nowrap bg-${unit.color} bg-opacity-20 rounded px-1.5 py-0.5 text-${unit.color}`}>
                {unit.name}
                {unit.id !== event.class_units[event.class_units.length - 1].id}
              </span>
            ))
          )
          : (
            <div className="flex flex-col text-gray-600 text-[10px] font-thin pt-1">
              {event.teachers?.slice(0, 3).map((teacher) => (
                <div key={teacher.id} className="truncate">
                  {teacher.user?.name}
                </div>
              ))}
              {event.teachers?.length > 3 && (
                <div className="text-gray-500">...</div>
              )}
            </div>
          )
        }
      </div>
      <div className="flex flex-col gap-1 mt-2">
        {event.assessment_events?.map(assessment => (
          <div key={assessment.id} className="truncate pt-1">
            <span className="bg-gray-200 text-gray-600 px-1.5 py-0.5 rounded text-[10px]">
              {assessment.title}
            </span>
          </div>
        ))}
      </div>
      {event.attendance && (
        <div className="absolute bottom-1 right-2">
          {attendanceIcons[event.attendance.attendance_type as keyof typeof attendanceIcons]}
        </div>
      )}
    </button>
  )
}

export { LessonButton }
