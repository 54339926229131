import React, { type ReactElement } from 'react'
import { useApiFetch } from '../../../hooks/api/useApiFetch'
import { useTranslation } from 'react-i18next'
import { API_ADMIN_LESSON_SCHEDULES_PATH } from '../../../constants/apiRoutes'
import { map } from 'underscore'
import { type LessonScheduleData } from '../../../constants/types'
import LessonScheduleListItem from '../../../components/admin/lesson_schedules/LessonScheduleListItem'
import Pagination from '../../../components/common/Pagination'

const LessonSchedulesView = (): ReactElement => {
  const { data: lessonSchedules, fetchData } = useApiFetch({ url: API_ADMIN_LESSON_SCHEDULES_PATH, paginated: true })
  const { t } = useTranslation('translations')

  return (
    <div className="w-full flex justify-center">
      <div className="w-5/6 pb-10 pt-10">
        <div className="flex justify-between pb-8 pt-4">
          <div className="text-xl font-bold font-['Inter'] leading-tight lg:block hidden">
            {t('admin.lesson_schedules.h1')}
          </div>
        </div>
        <div className="shadow-lg rounded-lg">
          <div className="card border border-gray-100 grid lg:grid-cols-12 rounded-t-lg py-6 px-5 bg-[#F9FAFB]">
            <div className="font-['Inter'] col-span-1 lg:block hidden">{t('admin.lesson_schedules.attributes.id')}</div>
            <div className="font-['Inter'] col-span-3 lg:block hidden">{t('admin.lesson_schedules.attributes.starts_on')}</div>
            <div className="font-['Inter'] col-span-3 lg:block hidden">{t('admin.lesson_schedules.attributes.ends_on')}</div>
            <div className="font-['Inter'] col-span-4 lg:block hidden">{t('admin.lesson_schedules.attributes.created_at')}</div>
            <div className="font-['Inter'] col-span-1" />
          </div>
          <div className="border border-gray-100 divide-y">
            {
              map(lessonSchedules?.data, (lessonSchedule: LessonScheduleData): ReactElement => {
                return <LessonScheduleListItem lessonSchedule={lessonSchedule} key={lessonSchedule.id} />
              })
            }
          </div>
          <div className="border border-gray-100">
            <div className="p-4 rounded-lg shadow-lg">
              <Pagination paginationMeta={lessonSchedules?.meta} fetchData={fetchData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LessonSchedulesView
