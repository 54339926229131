import React, { type ReactElement } from 'react'
import { type SubjectData } from '../../../constants/types'
import { ADMIN_EDIT_SUBJECT_PATH } from '../../../constants/adminRoutes'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useParseDate } from '../../../hooks/useParseDate'

const SubjectListItem = ({ subject }: { subject: SubjectData }): ReactElement => {
  const { t } = useTranslation('translations')
  const { toLocalDate } = useParseDate()

  return (
    <div className="p-4">
      <div className="lg:hidden space-y-4">
        <div className="flex flex-col space-y-2">
          <div className="flex items-center justify-between">
            <span className="text-sm font-medium text-gray-500">ID:</span>
            <span className="text-sm">{subject?.id}</span>
          </div>
          <div className="flex items-center justify-between">
            <span className="text-sm font-medium text-gray-500">Name:</span>
            <span className="text-sm">{subject?.name}</span>
          </div>
          <div className="flex items-center justify-between">
            <span className="text-sm font-medium text-gray-500">Color:</span>
            <div className="flex items-center space-x-2">
              <span className="text-sm">{subject?.color}</span>
              <div className={`h-5 w-5 rounded-md bg-${subject.color}`} />
            </div>
          </div>
          <div className="flex items-center justify-between">
            <span className="text-sm font-medium text-gray-500">Created:</span>
            <span className="text-sm">{toLocalDate(subject?.created_at)}</span>
          </div>
          <div className="flex items-center justify-between">
            <span className="text-sm font-medium text-gray-500">Updated:</span>
            <span className="text-sm">{toLocalDate(subject?.updated_at)}</span>
          </div>
        </div>
        <div className="flex justify-end pt-2">
          <Link
            to={ADMIN_EDIT_SUBJECT_PATH.replace(':id', subject?.id?.toString())}
            className="inline-flex items-center px-3 py-1 border border-[#3758F9] rounded-full text-sm text-[#3758F9]"
          >
            {t('admin.subjects.edit_button')}
          </Link>
        </div>
      </div>

      <div className="hidden lg:grid lg:grid-cols-12 lg:gap-4 lg:items-center">
        <div className="col-span-1 truncate">{subject?.id}</div>
        <div className="col-span-3 truncate">{subject?.name}</div>
        <div className="col-span-3 flex items-center space-x-2">
          <span className="truncate">{subject?.color}</span>
          <div className={`h-5 w-5 rounded-md bg-${subject.color}`} />
        </div>
        <div className="col-span-2 truncate">{toLocalDate(subject?.created_at)}</div>
        <div className="col-span-2 truncate">{toLocalDate(subject?.updated_at)}</div>
        <div className="col-span-1">
          <Link
            to={ADMIN_EDIT_SUBJECT_PATH.replace(':id', subject?.id?.toString())}
            className="inline-flex items-center px-3 py-1 border border-[#3758F9] rounded-full text-sm text-[#3758F9]"
          >
            {t('admin.subjects.edit_button')}
          </Link>
        </div>
      </div>
    </div>
  )
}

export default SubjectListItem
