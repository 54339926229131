import React, { type ReactElement, useState } from 'react'
import { Link } from 'react-router-dom'
import { EDIT_USER_PROFILE_PATH } from '../../../constants/routes'
import { API_ADMIN_DEACTIVATE_USER_PATH } from '../../../constants/apiRoutes'
import { type TeacherData } from '../../../constants/types'
import { useTranslation } from 'react-i18next'
import { useParseDate } from '../../../hooks/useParseDate'
import { useApiPost } from '../../../hooks/api/useApiPost'
import DefaultAvatar from '../../../assets/default_avatar.svg'

const TeacherListItem = ({ teacher, fetchData }: { teacher: TeacherData, fetchData: () => Promise<void> }): ReactElement => {
  const { t } = useTranslation('translations')
  const { toLocalDate } = useParseDate()
  const [isDeactivating, setIsDeactivating] = useState(false)

  const { sendData: deactivateTeacher } = useApiPost({
    url: API_ADMIN_DEACTIVATE_USER_PATH,
    successFlashMessage: 'Pomyślnie dezaktywowano'
  })

  const sendDeactivateTeacherRequest = async (userId: number): Promise<void> => {
    try {
      setIsDeactivating(true)
      await deactivateTeacher({
        apiUrl: API_ADMIN_DEACTIVATE_USER_PATH.replace(':id', userId.toString())
      })
      await fetchData()
      window.scrollTo({ top: 0, behavior: 'smooth' })
    } catch (error) {
      console.error('Failed to deactivate teacher:', error)
    } finally {
      setIsDeactivating(false)
    }
  }

  return (
    <div className="p-4">
      <div className="lg:hidden space-y-4">
        <div className="flex items-center space-x-4">
          <img
            src={teacher?.user?.avatar?.url || DefaultAvatar}
            alt={`teacher-avatar-${teacher.id}`}
            className="w-12 h-12 rounded-full"
          />
          <div className="flex-1 min-w-0">
            <p className="text-sm font-medium text-gray-900 truncate">
              {teacher?.user?.name}
            </p>
            <p className="text-sm text-gray-500 truncate">
              {teacher?.user?.email}
            </p>
            <p className="text-sm text-gray-500 hidden lg:block">
              {toLocalDate(teacher?.created_at)}
            </p>
          </div>
        </div>
        <div className="flex flex-wrap gap-2">
          <Link
            to={EDIT_USER_PROFILE_PATH.replace(':id', teacher?.user?.id?.toString())}
            className="inline-flex items-center px-3 py-1 border border-[#3758F9] rounded-full text-sm text-[#3758F9]"
          >
            {t('admin.teachers.edit_button')}
          </Link>
          {teacher?.user?.deactivated_at
            ? (
              <div className="inline-flex items-center px-3 py-1 border border-gray-500 rounded-full text-sm text-gray-500">
                {t('teachers.statuses.deactivated')}
              </div>
            )
            : (
              <button
                onClick={async () => await (!isDeactivating && sendDeactivateTeacherRequest(teacher.user.id))}
                disabled={isDeactivating}
                className="inline-flex items-center px-3 py-1 border border-[#3758F9] rounded-full text-sm text-[#3758F9] disabled:opacity-50"
              >
                {t('admin.teachers.deactivate_button')}
              </button>
            )}
        </div>
      </div>

      <div className="hidden lg:grid lg:grid-cols-12 lg:gap-4 lg:items-center">
        <div className="col-span-1 flex justify-center">
          <img
            src={teacher?.user?.avatar?.url || DefaultAvatar}
            alt={`teacher-avatar-${teacher.id}`}
            className="w-10 h-10 rounded-full"
          />
        </div>
        <div className="col-span-3 truncate">{teacher?.user?.name}</div>
        <div className="col-span-3 truncate">{teacher?.user?.email}</div>
        <div className="col-span-2 truncate">{toLocalDate(teacher?.created_at)}</div>
        <div className="col-span-3 flex gap-2">
          <Link
            to={EDIT_USER_PROFILE_PATH.replace(':id', teacher?.user?.id?.toString())}
            className="inline-flex items-center px-3 py-1 border border-[#3758F9] rounded-full text-sm text-[#3758F9]"
          >
            {t('admin.teachers.edit_button')}
          </Link>
          {teacher?.user?.deactivated_at
            ? (
              <div className="inline-flex items-center px-3 py-1 border border-gray-500 rounded-full text-sm text-gray-500">
                {t('teachers.statuses.deactivated')}
              </div>
            )
            : (
              <button
                onClick={async () => await (!isDeactivating && sendDeactivateTeacherRequest(teacher.user.id))}
                disabled={isDeactivating}
                className="inline-flex items-center px-3 py-1 border border-[#3758F9] rounded-full text-sm text-[#3758F9] disabled:opacity-50"
              >
                {t('admin.teachers.deactivate_button')}
              </button>
            )}
        </div>
      </div>
    </div>
  )
}

export default TeacherListItem
