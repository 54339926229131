import React, { type ReactElement } from 'react'
import { map } from 'underscore'
import { type AddressTypes } from '../../constants/types'
import { useTranslation } from 'react-i18next'

const AddressFormGroup = ({
  type,
  input,
  setInput,
  validationErrors
}: {
  type: AddressTypes
  input: any
  setInput: (fn: (prev: any) => any) => void
  validationErrors: Record<string, any>
}): ReactElement => {
  const { t } = useTranslation('translations')
  const addressKey = `${type}_address`
  const addressErrors = validationErrors[addressKey] || {}

  const updateAddress = (field: string, value: string): void => {
    setInput((prevInput: any) => ({
      ...prevInput,
      [addressKey]: {
        ...prevInput[addressKey],
        address_type: type,
        [field]: value
      }
    }))
  }

  return (
    <div className="mt-6">
      <div className="border border-gray-200 rounded-lg p-4 mb-6">
        <h3 className="text-xl font-semibold mb-4 capitalize">{`Adres ${t(`addresses.types.${type}`)}`}</h3>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="text-lg font-medium ml-1">{t('addresses.street')}</label>
            <input
              value={input[addressKey]?.street || ''}
              className="px-2 py-1 border border-gray-300 rounded-lg mb-1 mt-2 h-12 w-full"
              onChange={(e) => { updateAddress('street', e.target.value) }}
            />
            <div className="text-sm text-red-500">
              {map(addressErrors.street, (error: string): string =>
                `${t('addresses.street')} ${error}`
              ).join(', ')}
            </div>
          </div>

          <div>
            <label className="text-lg font-medium ml-1">{t('addresses.apartment_number')}</label>
            <input
              value={input[addressKey]?.apartment_number || ''}
              className="px-2 py-1 border border-gray-300 rounded-lg mb-1 mt-2 h-12 w-full"
              onChange={(e) => { updateAddress('apartment_number', e.target.value) }}
            />
            <div className="text-sm text-red-500">
              {map(addressErrors.apartment_number, (error: string): string =>
                `${t('addresses.apartment_number')} ${error}`
              ).join(', ')}
            </div>
          </div>

          <div>
            <label className="text-lg font-medium ml-1">{t('addresses.postcode')}</label>
            <input
              value={input[addressKey]?.postcode || ''}
              className="px-2 py-1 border border-gray-300 rounded-lg mb-1 mt-2 h-12 w-full"
              onChange={(e) => { updateAddress('postcode', e.target.value) }}
            />
            <div className="text-sm text-red-500">
              {map(addressErrors.postcode, (error: string): string =>
                `${t('addresses.postcode')} ${error}`
              ).join(', ')}
            </div>
          </div>

          <div>
            <label className="text-lg font-medium ml-1">{t('addresses.city')}</label>
            <input
              value={input[addressKey]?.city || ''}
              className="px-2 py-1 border border-gray-300 rounded-lg mb-1 mt-2 h-12 w-full"
              onChange={(e) => { updateAddress('city', e.target.value) }}
            />
            <div className="text-sm text-red-500">
              {map(addressErrors.city, (error: string): string =>
                `${t('addresses.city')} ${error}`
              ).join(', ')}
            </div>
          </div>

          <div>
            <label className="text-lg font-medium ml-1">{t('addresses.region')}</label>
            <input
              value={input[addressKey]?.region || ''}
              className="px-2 py-1 border border-gray-300 rounded-lg mb-1 mt-2 h-12 w-full"
              onChange={(e) => { updateAddress('region', e.target.value) }}
            />
            <div className="text-sm text-red-500">
              {map(addressErrors.region, (error: string): string =>
                `${t('addresses.region')} ${error}`
              ).join(', ')}
            </div>
          </div>

          <div>
            <label className="text-lg font-medium ml-1">{t('addresses.country')}</label>
            <input
              value={input[addressKey]?.country || ''}
              className="px-2 py-1 border border-gray-300 rounded-lg mb-1 mt-2 h-12 w-full"
              onChange={(e) => { updateAddress('country', e.target.value) }}
            />
            <div className="text-sm text-red-500">
              {map(addressErrors.country, (error: string): string =>
                `${t('addresses.country')} ${error}`
              ).join(', ')}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddressFormGroup
