import React, { useContext } from 'react'
import type { LessonInstancePopoverProps, LessonInstancePopoverContentProps } from '../../../constants/types'
import { Link } from 'react-router-dom'
import { useRolePath } from '../../../hooks/useRolePath'
import { CurrentUserContext } from '../../../contexts/users/CurrentUserContext'
import { LESSON_PATH } from '../../../constants/rolePaths'
import { USER_PROFILE_PATH } from '../../../constants/routes'
import DefaultAvatar from '../../../assets/default_avatar.svg'
import { useTranslation } from 'react-i18next'
import { TEACHER_ROLE } from '../../../constants/roles'

const LessonInstancePopover: React.FC<LessonInstancePopoverProps> = ({
  data,
  position,
  onClose,
  attendanceIcons
}) => {
  if (!position) {
    return (
      <>
        <div
          className="fixed inset-0 z-40"
          onClick={onClose}
        />
        <div className="fixed inset-x-4 bottom-0 -translate-y-1/4 z-50">
          <div
            className="bg-white shadow-lg w-full max-w-sm mx-auto rounded-xl"
            onClick={(e) => { e.stopPropagation() }}
          >
            <PopoverContent
              lessonInstance={data}
              onClose={onClose}
              isMobile={true}
              attendanceIcons={attendanceIcons}
            />
          </div>
        </div>
      </>
    )
  }

  return (
    <div
      className="absolute bg-white rounded-xl shadow-lg w-80 border border-gray-200"
      style={{
        top: position.top,
        left: position.left,
        zIndex: 999,
        position: 'absolute'
      }}
    >
      <PopoverContent
        lessonInstance={data}
        onClose={onClose}
        isMobile={false}
        attendanceIcons={attendanceIcons}
      />
    </div>
  )
}

const PopoverContent: React.FC<LessonInstancePopoverContentProps> = ({
  lessonInstance,
  onClose,
  isMobile,
  attendanceIcons
}) => {
  const { currentUser } = useContext(CurrentUserContext)
  const { t } = useTranslation('translations')
  const rolePath = useRolePath(LESSON_PATH, currentUser)
  const lessonViewPath = rolePath.replace(':id', lessonInstance.lesson.id.toString())
  const isTeacher = currentUser?.role === TEACHER_ROLE
  const date = new Date(lessonInstance.starts_at)
  const weekdays = [
    t('calendar.days_of_week.monday'),
    t('calendar.days_of_week.tuesday'),
    t('calendar.days_of_week.wednesday'),
    t('calendar.days_of_week.thursday'),
    t('calendar.days_of_week.friday'),
    t('calendar.days_of_week.saturday'),
    t('calendar.days_of_week.sunday')
  ]
  const dayOfWeek = weekdays[date.getDay()]

  const formatTime = (dateString: string | Date): string => {
    const date = typeof dateString === 'string' ? new Date(dateString) : dateString
    return date.toLocaleTimeString('pl-PL', { hour: '2-digit', minute: '2-digit' })
  }

  return (
    <>
      <button
        onClick={onClose}
        className={`absolute ${isMobile ? 'top-3 right-3' : 'top-2 right-2'} text-gray-500 hover:text-gray-700`}
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
        </svg>
      </button>
      <div>
        <h3 className={`font-semibold text-md border-l-${lessonInstance.subject.color} border-l-[3px] border-b rounded-t-xl pl-2 p-4`}>
          {lessonInstance.lesson.name}
        </h3>
        <div className="grid grid-cols-[1fr_3fr] gap-y-6 gap-x-3 px-4 pb-4 py-4 items-center">
          <div className="text-xs text-gray-500">
            {t('calendar.day')}
          </div>
          <div className="text-sm">
            {dayOfWeek}
          </div>

          <div className="text-xs text-gray-500">
            {t('calendar.hour')}
          </div>
          <div className="text-sm">
            {formatTime(lessonInstance.starts_at)} - {formatTime(lessonInstance.ends_at)}
          </div>

          {isTeacher
            ? (
              <>
                <div className="text-xs text-gray-500 self-start pt-2">
                  {t('calendar.class_units')}
                </div>
                <div className="flex flex-col space-y-2 items-start pt-1">
                  {lessonInstance.class_units.map((unit) => (
                    <div key={unit.id} className={`text-sm rounded px-1.5 py-0.5 bg-${unit.color} bg-opacity-20 text-${unit.color}`}>
                      <div className="font-medium">{unit.name}</div>
                    </div>
                  ))}
                </div>
              </>
            )
            : (
              <>
                <div className="text-xs text-gray-500 self-start pt-2">
                  {t('calendar.teacher')}
                </div>
                <div className="flex flex-col space-y-2 items-start pt-1">
                  {lessonInstance?.teachers?.slice(0, 3).map((teacher) => (
                    <Link
                      key={teacher.id}
                      to={USER_PROFILE_PATH.replace(':id', teacher.user?.id?.toString())}
                      className="flex gap-3 font-['DM Sans'] text-sm items-start"
                    >
                      <img
                        src={teacher.user?.avatar?.url ?? DefaultAvatar}
                        className="object-cover cursor-pointer h-5 w-5 rounded-full flex-shrink-0"
                        alt="Avatar"
                      />
                      {teacher.user?.name}
                    </Link>
                  ))}
                  {lessonInstance?.teachers?.length > 3 && (
                    <span className="text-gray-500 text-sm pl-8">...</span>
                  )}
                </div>
              </>
            )
          }

          {lessonInstance.classroom && (
            <>
              <div className="text-xs text-gray-500">
                Sala
              </div>
              <div className="text-sm">
                {lessonInstance.classroom.number}
              </div>
            </>
          )}

          {lessonInstance.assessment_events?.length > 0 && (
            <>
              <div>
              </div>
              <div className="flex flex-col gap-1 w-auto">
                {lessonInstance.assessment_events.map(assessment => (
                  <span key={assessment.id} className="bg-gray-200 text-gray-600 px-1.5 py-0.5 rounded text-[10px] inline-block w-fit font-extrabold">
                    {assessment.title}
                  </span>
                ))}
              </div>
            </>
          )}

          {lessonInstance.attendance && (
            <>
              <div className="text-xs text-gray-500 self-center">
                {t('calendar.attendance')}
              </div>
              <div className="flex items-center gap-2">
                <div className="shrink-0 h-7 w-7 flex items-center">
                  {attendanceIcons[lessonInstance.attendance.attendance_type as keyof typeof attendanceIcons]}
                </div>
                <span className="text-sm">
                  {lessonInstance.attendance.attendance_type === 'presence' && 'Obecność'}
                  {lessonInstance.attendance.attendance_type === 'absence' && 'Nieobecność'}
                  {lessonInstance.attendance.attendance_type === 'justified_absence' && 'Nieobecność usprawiedliwiona'}
                  {lessonInstance.attendance.attendance_type === 'school_presence' && 'Obecność (szkoła)'}
                  {lessonInstance.attendance.attendance_type === 'late' && 'Spóźnienie'}
                </span>
              </div>
            </>
          )}

          {lessonInstance.substitute_teacher && (
            <>
              <div className="text-xs text-gray-600">
                {t('calendar.substitution')}
              </div>
              {lessonInstance?.substitute_teacher?.user?.id && (
                <Link
                  to={USER_PROFILE_PATH.replace(':id', lessonInstance?.substitute_teacher?.user?.id?.toString())}
                  className="flex gap-3 font-['DM Sans'] text-sm"
                  key={lessonInstance?.substitute_teacher?.user?.id}
                >
                  <img
                    src={lessonInstance?.substitute_teacher?.user?.avatar.url ?? DefaultAvatar}
                    className="object-cover cursor-pointer h-5 w-5 rounded-full"
                    alt="Avatar"
                  />
                  {lessonInstance?.substitute_teacher?.user?.name}
                </Link>
              )}
            </>
          )}
        </div>
      </div>
      <Link
        to={lessonViewPath}
        className="flex items-center justify-end gap-2 pt-3 pb-5 px-5 text-sm text-blue-800"
      >
        {t('calendar.see_lesson')}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="h-5 w-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
          />
        </svg>
      </Link>
    </>
  )
}

export default LessonInstancePopover
