import React, { type ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { ADMIN_NEW_LESSON_SCHEDULE_IMPORT_PATH } from '../../../constants/adminRoutes'
import { map } from 'underscore'
import { type LessonScheduleImportData } from '../../../constants/types'
import LessonScheduleImportListItem from '../../../components/admin/lesson_schedule_imports/LessonScheduleImportListItem'
import Pagination from '../../../components/common/Pagination'
import { useApiFetch } from '../../../hooks/api/useApiFetch'
import { API_ADMIN_LESSON_SCHEDULE_IMPORTS_PATH } from '../../../constants/apiRoutes'
import { useTranslation } from 'react-i18next'
import circlePlusIcon from '../../../assets/icons/plus-circle.svg'

const LessonScheduleImportsView = (): ReactElement => {
  const { data: lessonScheduleImports, fetchData } = useApiFetch({ url: API_ADMIN_LESSON_SCHEDULE_IMPORTS_PATH, paginated: true })
  const { t } = useTranslation('translations')

  return (
    <div className="w-full flex justify-center">
      <div className="w-full pb-10 pt-5 lg:px-14 px-5">
        <div className="flex justify-between pb-8 pt-4 ">
          <div className="text-neutral-800 text-[32px] font-semibold font-['DM Sans'] leading-[48px] tracking-tight">
            {t('admin.lesson_schedule_imports.h1')}
          </div>
          <div className="bg-blue-500 hover:bg-blue-700 text-white py-3 px-5 rounded md:w-fit">
            <Link
              to={ADMIN_NEW_LESSON_SCHEDULE_IMPORT_PATH}
              className="flex items-center"
            >
              {t('admin.lesson_schedule_imports.add_button')} <img src={circlePlusIcon} className="h-5 w-auto ml-2 background-white" />
            </Link>
          </div>
        </div>

        <div className="rounded-md shadow-md border border-gray-200">
          <div className="card grid grid-cols-12 border border-gray-100 border-b-gray-300 bg-gray-100 py-3 px-5 bg-[#F9FAFB] text-sm text-gray-500 rounded-t-md">
            <div className="col-span-1">
              <div className="flex space-x-2">
                <div className="font-['DM Sans']">
                  {t('admin.lesson_schedule_imports.attributes.id')}
                </div>
              </div>
            </div>

            <div className="col-span-1">
              <div className="flex space-x-2">
                <div className="font-['DM Sans']">
                  {t('admin.lesson_schedule_imports.attributes.created_at')}
                </div>
              </div>
            </div>

            <div className="col-span-3">
              <div className="flex space-x-2 justify-center">
                <div className="font-['DM Sans']">
                  {t('admin.lesson_schedule_imports.attributes.status')}
                </div>
              </div>
            </div>

            <div className="col-span-5">
              <div className="flex space-x-2">
                <div className="font-['DM Sans']">
                  {t('admin.lesson_schedule_imports.attributes.failure_reason')}
                </div>
              </div>
            </div>

            <div className="col-span-2" />
          </div>
          <div className="divide-y">
            {
              map(lessonScheduleImports.data, (lessonScheduleImport: LessonScheduleImportData): ReactElement => (
                <LessonScheduleImportListItem lessonScheduleImport={lessonScheduleImport} key={lessonScheduleImport.id} />
              ))
            }
          </div>
          <div className="p-4 rounded-b-lg border border-transparent border-t-gray-200">
            <Pagination paginationMeta={lessonScheduleImports.meta} fetchData={fetchData} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default LessonScheduleImportsView
