import React, { type FormEvent, type ReactElement, useEffect } from 'react'
import { map, has, pick } from 'underscore'
import { useTranslation } from 'react-i18next'
import { useParseDate } from '../../../hooks/useParseDate'
import { useApiFetch } from '../../../hooks/api/useApiFetch'
import {
  API_ADMIN_CALENDAR_EVENT_TYPES_PATH,
  API_ADMIN_CLASS_UNITS_PATH,
  API_ADMIN_ROLES_PATH
} from '../../../constants/apiRoutes'
import { useApiPost } from '../../../hooks/api/useApiPost'
import { ADMIN_CALENDAR_EVENTS_PATH } from '../../../constants/adminRoutes'
import { type CalendarEventData, type ClassUnitData, type RoleData } from '../../../constants/types'
import Select from '../../common/Select'
import MultiSelect from '../../common/MultiSelect'

const CalendarEventForm = (
  { calendarEvent, url, method = 'post' }:
  { url: string, calendarEvent?: CalendarEventData, method?: string }
): ReactElement => {
  const { t } = useTranslation('translations')
  const { toInputDate, toInputDateTime } = useParseDate()

  const { setInput, sendData, validationErrors, input } = useApiPost({ url, redirect: ADMIN_CALENDAR_EVENTS_PATH, successFlashMessage: 'Pomyślnie zapisano' })
  const { data: eventTypes } = useApiFetch({ url: API_ADMIN_CALENDAR_EVENT_TYPES_PATH })
  const { data: classUnits } = useApiFetch({ url: API_ADMIN_CLASS_UNITS_PATH, defaultQuery: { status: 'active' } })
  const { data: roles } = useApiFetch({ url: API_ADMIN_ROLES_PATH })

  useEffect(() => {
    if (calendarEvent) {
      setInput((_p: any) => {
        const allDay = has(calendarEvent, 'all_day') ? calendarEvent.all_day : false
        const allClasses = has(calendarEvent, 'all_classes') ? calendarEvent.all_classes : false
        const classUnits = map(calendarEvent.class_units ?? [], (classUnit: ClassUnitData) => classUnit.id.toString())
        const roleIds = map(calendarEvent.roles ?? [], (role: RoleData) => role.id.toString())

        return {
          ...pick(calendarEvent, ['title', 'description', 'event_type', 'starts_at', 'ends_at']),
          all_day: allDay,
          all_classes: allClasses,
          class_unit_ids: classUnits,
          role_ids: roleIds
        }
      })
    } else {
      setInput((_p: any) => ({ all_day: false, all_classes: false }))
    }
  }, [calendarEvent])

  const handleSubmit = async (e: FormEvent): Promise<void> => {
    e.preventDefault()
    await sendData({ method })
  }

  const renderDateInputs = (): ReactElement => {
    return (
      <>
        <label className='text-lg font-medium ml-1'>{t('admin.calendar_events.attributes.starts_at')}</label>
        <br />
        <input
          type={input.all_day ? 'date' : 'datetime-local'}
          value={input.all_day ? toInputDate(input.starts_at) : toInputDateTime(input.starts_at)}
          className='border border-gray-300 rounded-lg shadow-sm mt-2 px-5 py-4 w-full lg:w-2/3 cursor-pointer'
          onChange={(e) => { setInput((prevInput: any) => ({ ...prevInput, starts_at: e.target.value })) }}
        />
        <div className='text-sm text-red-500'>
          {map(validationErrors.starts_at, (error: string): string => `${t('admin.calendar_events.attributes.starts_at')} ${error}`).join(', ')}
        </div>
        <br />
        <label className='text-lg font-medium ml-1'>{t('admin.calendar_events.attributes.ends_at')}</label>
        <br />
        <input
          type={input.all_day ? 'date' : 'datetime-local'}
          value={input.all_day ? toInputDate(input.ends_at) : toInputDateTime(input.ends_at)}
          className='border border-gray-300 rounded-lg shadow-sm mt-2 px-5 py-4 w-full lg:w-2/3 cursor-pointer'
          onChange={(e) => { setInput((prevInput: any) => ({ ...prevInput, ends_at: e.target.value })) }}
        />
        <div className='text-sm text-red-500'>
          {map(validationErrors.ends_at, (error: string): string => `${t('admin.calendar_events.attributes.ends_at')} ${error}`).join(', ')}
        </div>
        <br />
      </>
    )
  }

  return (
    <form method={method} onSubmit={handleSubmit}>
      <label className='text-lg font-medium ml-1'>{t('admin.calendar_events.attributes.title')}</label>
      <br />
      <input
        value={input.title}
        className='px-2 py-1 border border-gray-300 rounded-lg mb-3 mt-3 h-12 w-full lg:w-2/3'
        onChange={(e) => { setInput((prevInput: any) => ({ ...prevInput, title: e.target.value })) }}
      />
      <div className='text-sm text-red-500'>
        {map(validationErrors.title, (error: string): string => `${t('admin.calendar_events.attributes.title')} ${error}`).join(', ')}
      </div>
      <br />
      <label className='text-lg font-medium ml-1'>{t('admin.calendar_events.attributes.description')}</label>
      <br />
      <textarea
        rows={6}
        value={input.description}
        className='px-2 py-1 border border-gray-300 rounded-lg mt-3 mb-3 py-2 resize-none w-full lg:w-2/3'
        onChange={(e) => { setInput((prevInput: any) => ({ ...prevInput, description: e.target.value })) }}
      />
      <div className='text-sm text-red-500'>
        {map(validationErrors.description, (error: string): string => `${t('admin.calendar_events.attributes.description')} ${error}`).join(', ')}
      </div>
      <br />
      <label className='text-lg font-medium ml-1'>{t('admin.calendar_events.attributes.event_type')}</label>
      <br />
      <div className='relative w-full lg:w-2/3'>
        <Select
          id="event-type-select"
          placeholder={t('admin.calendar_events.event_types.placeholder')}
          value={input.event_type}
          options={map(eventTypes, (eventType) => ({ text: t(`admin.calendar_events.event_types.${eventType}`), value: eventType }))}
          onChange={(selectedValue: string) => {
            setInput((prevInput: any) => ({
              ...prevInput,
              event_type: selectedValue
            }))
          }}
        />
      </div>
      <div className='text-sm text-red-500'>
        {map(validationErrors.event_type, (error: string): string => `${t('admin.calendar_events.attributes.event_type')} ${error}`).join(', ')}
      </div>
      <br />

      <label className="flex items-center cursor-pointer justify-start">
        <input
          type="checkbox"
          checked={input.all_day}
          className="sr-only peer"
        />
        <div
          className="relative w-11 h-6 bg-gray-200 ring-gray-500 rounded-full
                             peer peer-checked:ring-blue-600 bg-gray-500
                             peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full
                             peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px]
                             after:start-[2px] after:bg-white after:border-gray-300 after:rounded-full
                             after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"
          onClick={() => { setInput((prevInput) => ({ ...prevInput, all_day: !input.all_day, starts_at: '', ends_at: '' })) }}
        >
        </div>
        <div className="col-span-1 block lg:hidden"></div>
        <span className='text-base text-gray-600 col-span-4 lg:col-span-5 ml-3'>
          {t('admin.calendar_events.attributes.all_day')}
        </span>
      </label>
      <div className='text-sm text-red-500'>
        {map(validationErrors.all_day, (error: string): string => `${t('admin.calendar_events.attributes.all_day')} ${error}`).join(', ')}
      </div>

      <br />
      {renderDateInputs()}

      <br />
      <label className="flex items-center cursor-pointer justify-start">
        <input
          type="checkbox"
          checked={input.all_classes}
          className="sr-only peer"
        />
        <div
          className="relative w-11 h-6 bg-gray-200 ring-gray-500 rounded-full
                             peer peer-checked:ring-blue-600 bg-gray-500
                             peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full
                             peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px]
                             after:start-[2px] after:bg-white after:border-gray-300 after:rounded-full
                             after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"
          onClick={() => { setInput((prevInput) => ({ ...prevInput, all_classes: !input.all_classes })) }}
        >
        </div>
        <div className="col-span-1 block lg:hidden"></div>
        <span className='text-base text-gray-600 col-span-4 lg:col-span-5 ml-3'>
          {t('admin.calendar_events.attributes.all_classes')}
        </span>
      </label>
      <div className='text-sm text-red-500'>
        {map(validationErrors.all_classes, (error: string): string => `${t('admin.calendar_events.attributes.all_classes')} ${error}`).join(', ')}
      </div>

      <br />

      { !input.all_classes && (
        <>
          <MultiSelect
            id="calendar-event-classes-select"
            className="w-full lg:w-2/3"
            search={true}
            dropdownClasses="w-full"
            placeholder={t('admin.calendar_events.class_units.placeholder')}
            defaultSelected={input.class_unit_ids}
            options={map(classUnits, (classUnit: ClassUnitData) => ({ text: classUnit.name, value: classUnit.id.toString() }))}
            onChange={(selectedValues: string) => {
              const selectedClassUnitIds = selectedValues.split(',')
              setInput((prevInput: any) => ({
                ...prevInput,
                class_unit_ids: map(selectedClassUnitIds, (id) => parseInt(id))
              }))
            }}
          />
          <div className='text-sm text-red-500'>
            {map(validationErrors.class_unit_ids, (error: string): string => `${t('admin.calendar_events.attributes.class_unit_ids')} ${error}`).join(', ')}
          </div>
        </>
      )}

      <br />

      <div className="space-y-2">
        <label className='text-lg font-medium ml-1'>{t('admin.calendar_events.attributes.role_ids')}</label>
        <MultiSelect
          id="calendar-event-roles-select"
          className="w-full lg:w-2/3"
          dropdownClasses="w-full"
          placeholder={t('admin.calendar_events.roles.placeholder')}
          defaultSelected={input.role_ids}
          options={map(roles, (role: RoleData) => ({ text: t(`navbar.roles.${role.name}`), value: role.id.toString() }))}
          onChange={(selectedValues: string) => {
            const selectedRoleIds = selectedValues.split(',')
            setInput((prevInput: any) => ({
              ...prevInput,
              role_ids: map(selectedRoleIds, (id) => parseInt(id))
            }))
          }}
        />
        <p className="text-sm text-gray-500 ml-1">
          Wybór ról jest opcjonalny. Jeśli nie wybierzesz żadnej, wydarzenie będzie widoczne dla wszystkich.
        </p>
        <div className='text-sm text-red-500'>
          {map(validationErrors.role_ids, (error: string): string => `${t('admin.calendar_events.attributes.role_ids')} ${error}`).join(', ')}
        </div>
      </div>

      <div className='grid place-items-end'>
        <button type='submit' className='bg-blue-500 hover:bg-blue-700 text-white py-3 px-6 rounded md:mt-10'>
          {t('admin.calendar_events.submit_button')}
        </button>
      </div>
    </form>
  )
}

export default CalendarEventForm
