import React, { type ReactElement, useContext } from 'react'
import { Link } from 'react-router-dom'
import { ADMIN_EDIT_CALENDAR_EVENT_PATH } from '../../../constants/adminRoutes'
import { API_ADMIN_EDIT_CALENDAR_EVENT_PATH } from '../../../constants/apiRoutes'
import { type CalendarItemData, FlashType } from '../../../constants/types'
import { useTranslation } from 'react-i18next'
import { useParseDate } from '../../../hooks/useParseDate'
import { useApiPost } from '../../../hooks/api/useApiPost'
import { FlashContext } from '../../../contexts/flash/FlashContext'

const CalendarEventsListItem = ({ calendarEvent }: { calendarEvent: CalendarItemData }): ReactElement => {
  const { t } = useTranslation('translations')
  const { toLocalDate } = useParseDate()
  const { setFlash } = useContext(FlashContext)

  const { sendData: deleteCalendarEvent } = useApiPost({
    url: API_ADMIN_EDIT_CALENDAR_EVENT_PATH.replace(':id', calendarEvent?.id?.toString())
  })

  const sendDeleteRequest = async (): Promise<void> => {
    try {
      await deleteCalendarEvent({ method: 'delete' })
      window.location.reload()
      // FIXME: Fix flash setting
      setFlash({ message: 'Pomyślnie usunięto', type: FlashType.success })
    } catch (error) {
      setFlash({ message: 'Nie udało się usunąć wydarzenia', type: FlashType.error })
    }
  }

  const handleDeleteClick = (event: React.MouseEvent<SVGSVGElement>): void => {
    const confirmMessage = event.currentTarget.getAttribute('data-confirm')
    if (!confirmMessage || window.confirm(confirmMessage)) {
      sendDeleteRequest().catch(console.error)
    }
  }

  return (
    <div className="card grid grid-cols-4 lg:grid-cols-10 py-3 px-5 text-sm flex items-center">
      <div className="col-span-2 truncate hidden lg:block">{calendarEvent?.title}</div>
      <div className="col-span-3 opacity-50 truncate hidden lg:block">{calendarEvent?.description}</div>
      <div className="col-span-1 truncate hidden lg:block mr-2">
        {t(`admin.calendar_events.event_types.${calendarEvent?.event_type}`)}
      </div>
      <div className="col-span-1 truncate hidden lg:block">{toLocalDate(calendarEvent?.starts_at)}</div>
      <div className="col-span-1 truncate hidden lg:block">{toLocalDate(calendarEvent?.ends_at)}</div>
      <div className="col-span-3 block lg:hidden pr-2">
        ID {calendarEvent?.id}
        <br />
        <div className="truncate">{calendarEvent?.title}</div>
        <div className="opacity-50 truncate">{calendarEvent?.description}</div>
        {t(`admin.calendar_events.event_types.${calendarEvent?.event_type}`)}
        <br />
        {toLocalDate(calendarEvent?.starts_at)} - {toLocalDate(calendarEvent?.ends_at)}
      </div>
      <div className="col-span-1">
        <Link
          to={ADMIN_EDIT_CALENDAR_EVENT_PATH.replace(':id', calendarEvent?.id?.toString())}
          className="px-4 py-1 w-max border border-1 border-[#3758F9] rounded-2xl text-[#3758F9]"
        >
          {t('admin.calendar_events.edit_button')}
        </Link>
      </div>
      <div className="col-span-1 flex items-center justify-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          className="w-5 h-5 cursor-pointer"
          onClick={handleDeleteClick}
          data-confirm="Na pewno chcesz usunąć wydarzenie?"
        >
          <path
            fillRule="evenodd"
            d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z"
            clipRule="evenodd"
          />
        </svg>
      </div>
    </div>
  )
}

export default CalendarEventsListItem
