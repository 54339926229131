import React, { useState } from 'react'
import { type DatePickerWithHourProps } from '../../../constants/types'

const generateDaysInMonth = (year: number, month: number): Date[] => {
  const days: Date[] = []
  const date = new Date(year, month, 1)
  while (date.getMonth() === month) {
    days.push(new Date(date))
    date.setDate(date.getDate() + 1)
  }
  return days
}

const getFirstDayOfMonth = (year: number, month: number): number => {
  return new Date(year, month, 1).getDay() // Sunday = 0, Monday = 1, etc.
}

const getPreviousMonthDays = (year: number, month: number, count: number): Date[] => {
  const days: Date[] = []
  const lastDayOfPreviousMonth = new Date(year, month, 0).getDate() // Last day of the previous month
  for (let i = count - 1; i >= 0; i--) {
    days.push(new Date(year, month - 1, lastDayOfPreviousMonth - i))
  }
  return days
}

const getNextMonthDays = (year: number, month: number, count: number): Date[] => {
  const days: Date[] = []
  for (let i = 1; i <= count; i++) {
    days.push(new Date(year, month + 1, i))
  }
  return days
}

const DatePickerWithHour: React.FC<DatePickerWithHourProps> = ({
  selectedDate,
  onSelectDate,
  closeDatePicker
}) => {
  const today = selectedDate ?? new Date()
  const [currentDate, setCurrentDate] = useState(today)
  const [selectedHour, setSelectedHour] = useState(today.getHours())
  const [selectedMinute, setSelectedMinute] = useState(today.getMinutes())

  const handleDateClick = (date: Date): void => {
    const updatedDate = new Date(date)
    updatedDate.setHours(selectedHour)
    updatedDate.setMinutes(selectedMinute)
    onSelectDate(updatedDate.toISOString())
    if (closeDatePicker) closeDatePicker()
  }

  const handleTimeChange = (type: 'hour' | 'minute', value: number): void => {
    if (type === 'hour') setSelectedHour(value)
    if (type === 'minute') setSelectedMinute(value)
  }

  const daysInMonth = generateDaysInMonth(currentDate.getFullYear(), currentDate.getMonth())
  const firstDayOfWeek = getFirstDayOfMonth(currentDate.getFullYear(), currentDate.getMonth())

  const previousMonthDays = getPreviousMonthDays(currentDate.getFullYear(), currentDate.getMonth(), firstDayOfWeek)

  const totalDays = previousMonthDays.length + daysInMonth.length
  const remainingCells = totalDays % 7 === 0 ? 0 : 7 - (totalDays % 7)
  const nextMonthDays = getNextMonthDays(currentDate.getFullYear(), currentDate.getMonth(), remainingCells)

  return (
    <div className="p-5 space-y-2 z-50 absolute bg-white border shadow-lg rounded-lg">
      <div className="flex justify-between items-center space-x-3 pb-3">
        <button
          type="button"
          onClick={() => { setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1)) }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
          </svg>
        </button>
        <div className="flex gap-2">
          <div>{currentDate.toLocaleString('pl-PL', { month: 'long' }).toUpperCase()}</div>
          <div>{currentDate.getFullYear()}</div>
        </div>
        <button
          type="button"
          onClick={() => { setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1)) }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
          </svg>
        </button>
      </div>

      <div className="grid grid-cols-7 gap-2 text-center text-sm">
        <div>Nd</div>
        <div>Pn</div>
        <div>Wt</div>
        <div>Śr</div>
        <div>Czw</div>
        <div>Pt</div>
        <div>Sb</div>
      </div>

      <div className="grid grid-cols-7 gap-2">
        {previousMonthDays.map((day) => (
          <button
            key={day.toISOString()}
            className="py-2 px-2 text-gray-400 hover:bg-gray-100 rounded-full"
            onClick={() => { handleDateClick(day) }}
          >
            {day.getDate()}
          </button>
        ))}

        {daysInMonth.map((day) => (
          <button
            key={day.toISOString()}
            className={`py-2 px-2 w-10 h-10 ${day.getDate() === currentDate.getDate() ? 'bg-blue-500 text-white' : 'hover:bg-gray-300'} rounded-full`}
            onClick={() => { handleDateClick(day) }}
          >
            {day.getDate()}
          </button>
        ))}

        {nextMonthDays.map((day) => (
          <button
            key={day.toISOString()}
            className="py-2 px-2 text-gray-400 hover:bg-gray-100 rounded-full"
            onClick={() => { handleDateClick(day) }}
          >
            {day.getDate()}
          </button>
        ))}
      </div>

      <div className="flex justify-between items-center px-2 py-3">
        <div>
          <label>Godzina: </label>
          <select value={selectedHour} onChange={(e) => { handleTimeChange('hour', parseInt(e.target.value)) }}>
            {Array.from({ length: 24 }, (_, i) => (
              <option key={i} value={i}>
                {i < 10 ? `0${i}` : i}
              </option>
            ))}
          </select>
          <select value={selectedMinute} onChange={(e) => { handleTimeChange('minute', parseInt(e.target.value)) }}>
            {Array.from({ length: 60 }, (_, i) => (
              <option key={i} value={i}>
                {i < 10 ? `0${i}` : i}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="flex justify-end gap-2 mt-3">
        <button onClick={closeDatePicker} className="px-4 py-2 border rounded">
          Anuluj
        </button>
        <button onClick={() => { handleDateClick(currentDate) }} className="px-4 py-2 bg-blue-500 text-white rounded">
          Wybierz
        </button>
      </div>
    </div>
  )
}

export default DatePickerWithHour
