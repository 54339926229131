import { type UseParseDateMethods } from '../constants/types'

export const useParseDate = (): UseParseDateMethods => {
  const toLocalDate = (date: Date): string => {
    if (!date) return ''

    return new Date(date).toLocaleDateString(
      'pl-PL',
      { day: '2-digit', month: '2-digit', year: 'numeric' }
    )
  }

  const toLocalDateWithHour = (date: Date): string => {
    if (!date) return ''

    return new Date(date).toLocaleDateString(
      'pl-PL',
      { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' }
    )
  }

  const toLocalHour = (date: Date): string => {
    if (!date) return ''

    return new Date(date).toLocaleTimeString(
      'pl-PL',
      { hour: '2-digit', minute: '2-digit', hour12: false }
    )
  }

  const toInputDate = (date: Date): string => {
    if (!date) return ''

    return new Date(date).toISOString().split('T')[0]
  }

  const toInputDateTime = (date: Date | string): string => {
    if (!date) return ''

    const parsedDate = typeof date === 'string' ? new Date(date) : date
    const offsetDate = new Date(parsedDate.getTime() - parsedDate.getTimezoneOffset() * 60000)
    const isoString = offsetDate.toISOString()
    const [datePart, timePart] = isoString.split('T')
    const timeWithoutMs = timePart.split('.')[0]

    return `${datePart}T${timeWithoutMs}`
  }

  const toShortHumanizedDate = (date: Date): string => {
    if (!date) return ''

    return new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
  }

  return { toLocalDate, toLocalDateWithHour, toLocalHour, toInputDate, toShortHumanizedDate, toInputDateTime }
}
