import React, { type ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { ADMIN_NEW_SUBJECT_PATH } from '../../../constants/adminRoutes'
import { API_ADMIN_SUBJECTS_PATH } from '../../../constants/apiRoutes'
import Plus from '../../../assets/icons/plus.svg'
import { map } from 'underscore'
import SubjectListItem from '../../../components/admin/subjects/SubjectListItem'
import SubjectsFilters from '../../../components/admin/subjects/SubjectsFilters'
import Pagination from '../../../components/common/Pagination'
import { useTranslation } from 'react-i18next'
import { useApiFetch } from '../../../hooks/api/useApiFetch'

const SubjectsView = (): ReactElement => {
  const { t } = useTranslation('translations')
  const apiUrl = `${API_ADMIN_SUBJECTS_PATH}?paginated=true`
  const { data: subjects, fetchData, query, setQuery } = useApiFetch({ url: apiUrl, paginated: true })

  return (
    <div className="w-full px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto py-6 sm:py-8">
        <div className="flex flex-col sm:flex-row justify-between gap-4 mb-6">
          <h1 className="text-xl font-bold font-['Inter'] leading-tight">
            {t('admin.subjects.h1')}
          </h1>

          <div className="flex flex-col sm:flex-row gap-4 items-start sm:items-center">
            <SubjectsFilters
              fetchSubjects={fetchData}
              query={query}
              setQuery={setQuery}
            />

            <Link
              to={ADMIN_NEW_SUBJECT_PATH}
              className="inline-flex items-center px-4 py-2 bg-[#3758F9] rounded-lg text-white text-sm whitespace-nowrap"
            >
              {t('admin.subjects.add_button')}
              <img src={Plus} alt="plus.svg" className="ml-2 w-4 h-4" />
            </Link>
          </div>
        </div>

        <div className="bg-white shadow rounded-lg overflow-hidden">
          <div className="hidden lg:grid lg:grid-cols-12 bg-[#F9FAFB] py-4 px-4">
            <div className="font-['Inter'] col-span-1">{t('admin.subjects.attributes.id')}</div>
            <div className="font-['Inter'] col-span-3">{t('admin.subjects.attributes.name')}</div>
            <div className="font-['Inter'] col-span-3">{t('admin.subjects.attributes.color')}</div>
            <div className="font-['Inter'] col-span-2">{t('admin.subjects.attributes.created_at')}</div>
            <div className="font-['Inter'] col-span-2">{t('admin.subjects.attributes.updated_at')}</div>
            <div className="col-span-1" />
          </div>

          <div className="divide-y divide-gray-200">
            {map(subjects?.data, (subject): ReactElement => (
              <SubjectListItem
                subject={subject}
                key={subject.id}
              />
            ))}
          </div>

          <div className="border-t border-gray-200">
            <div className="px-4 py-4">
              <Pagination
                paginationMeta={subjects?.meta}
                fetchData={fetchData}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubjectsView
