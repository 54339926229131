import React from 'react'
import type { CalendarEventData } from '../../../constants/types'
import { useParseDate } from '../../../hooks/useParseDate'

const CalendarEventButton = ({
  event,
  onClick,
  isActive = false
}: {
  event: CalendarEventData
  onClick: (event: CalendarEventData, e: React.MouseEvent<HTMLButtonElement>) => void
  isActive?: boolean
}): JSX.Element | null => {
  const { toLocalHour } = useParseDate()

  if (!event?.title) {
    return null
  }

  return (
    <button
      className={`px-2 py-2 h-full flex flex-col w-full items-start text-xs font-extrabold text-left border ${
        isActive ? 'border-blue-500' : 'border-gray-200'
      } border-l-[3px] border-l-stone-500 bg-white rounded shadow-sm`}
      onClick={(e) => { onClick(event, e) }}
    >
      <div className="w-full truncate text-[11px]">
        {event.title}
      </div>
      <div className="w-full truncate text-gray-600 font-normal text-[9px]">
        {event.description}
      </div>
      <div className="w-full truncate font-normal text-[9px]">
        {toLocalHour(event.starts_at)} - {toLocalHour(event.ends_at)}
      </div>
    </button>
  )
}

export { CalendarEventButton }
