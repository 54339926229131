import React, { type ReactElement } from 'react'
import { API_ADMIN_SUBJECTS_PATH } from '../../../constants/apiRoutes'
import SubjectForm from '../../../components/admin/subjects/SubjectForm'
import { useTranslation } from 'react-i18next'

const NewSubjectView = (): ReactElement => {
  const { t } = useTranslation('translations')

  return (
    <div className="grid lg:w-1/2" >
      <div className="px-5 lg:px-14 py-8">
        <h1 className="text-2xl lg:text-3xl text-left pb-12">{t('admin.subjects.new_header')}</h1>
        <SubjectForm url={API_ADMIN_SUBJECTS_PATH}/>
      </div>
    </div>
  )
}

export default NewSubjectView
